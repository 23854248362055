import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import moment from 'moment'

import config from '../../config'

const labelMap = {
  first_name: 'Fornavn',
  last_name: 'Etternavn',
  email: 'E-post',
  phone: 'Telefon',
  address: 'Adresse',
  postal_area: 'Poststed',
  postal_code: 'Postnummer',
  sim_type: 'SIM-type',
  sim_number: 'SIM-nummer',
  checkboxes: 'Checkbokser',
  childs_name: 'Barnets navn',
  porting_date: 'Porteringsdato',
  campaign_name: 'Kampanjenavn',
  extra_services: 'Tilleggstjenester',
  combined_invoice: 'Samlefaktura',
  subscription_name: 'Produktnavn',
  childs_date_of_birth: 'Barnets fødselsdag',
  sign_method: 'Signeringsmetode',
  sign_value: 'Signatur',
  lookup_method: 'Oppslagstype',
  latitude: 'Breddegrad',
  longitude: 'Lengdegrad',
  source_type: 'Salgskanal',
}

class ActivityDetailsComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      activity: null,
    }
  }

  componentDidMount() {

    console.log('this.props.layout.data', this.props.layout.data)
    axios.get(`${config.newApiUrl}/submissions/${this.props.layout.data.submissionId}`)
      .then(res => {
        if (res.data) {
          this.setState({activity: res.data.data})
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  renderValue = (key, value) => {
    const dateFormat = 'D.MM.YYYY'
    const dateTimeFormat = 'D.MM.YYYY hh:mm:ss'

    switch (key) {
      case 'date_of_birth':
      case 'porting_date':
        if (value) {
          return moment(value).format(dateFormat)
        }
        return value
      case 'created_at':
      case 'updated_at':
      case 'deleted_at':
        if (value) {
          return moment(value).format(dateTimeFormat)
        }
        return value
      case 'sign_value':
        if (value && value.startsWith('data:')) {
          return <img alt={key} src={value} />
        }
        return value
      case 'reported':
      case 'botSuccess':
        return value === true ? 'ja' : 'nei'
      default:
        return value
    }
  };

  renderLabel = ( key ) => {
    return labelMap[key] ? labelMap[key] : key
  };

  render () {
    const { activity } = this.state
    const renderValue = this.renderValue
    let content = <div>Loading...</div>
    console.log('activity', activity)

    if (activity && activity.id) {
      content = (
         <main>
          <div className={`main-body --wide-layout-temp-class --single-sales`}>
            <div className="tm-section-card">
              <h1>Detaljer om innsending</h1>
              <pre className="json">
                {JSON.stringify(activity, null, 2)}
              </pre>
            </div>
          </div>
        </main>
      )
    }
    return content
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const ActivityDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityDetailsComponent);

export default ActivityDetails
