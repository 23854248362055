import React from 'react'
import ReactDOM from 'react-dom'
import logger from 'redux-logger'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import ReduxToastr from 'react-redux-toastr'

// Think about maybe using RxJS for observables

// Redux
import Reducers from './reducers';

// Update to sass soon
import './styles/global-styles/index.scss'

import App from './containers/app';
import { unregister } from './registerServiceWorker';

import { checkIfLoggedIn } from './auth';
import { setRoute } from './router'

let store = createStore(Reducers, applyMiddleware(logger));
checkIfLoggedIn({ store: store });
setRoute({ store: store })

window.addEventListener('popstate', () => {
  setRoute({ store: store })
})

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <App />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar />
    </React.Fragment>
  </Provider>,
  document.getElementById('root'));
unregister();
