import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'

import config from '../../../config'

import { unsetModal } from '../../../actions/modal'

class AddSubscription extends Component {
  constructor(props){
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/users/${this.props.modal.userId}`)
      .then(r => {
        this.setState({
          firstName: r.data.firstName || '',
          lastName: r.data.lastName || '',
          email: r.data.email || '',
        })
      })
      .catch(e => console.error(e))
  }

  addSubscription = e => {
    e.preventDefault()
    console.log('clicked')

    axios.put(`${config.apiUrl}/users/${this.props.modal.userId}`, this.state)
      .then(res => {
        toastr.success('bruker oppdatert')
        window.location.reload()
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere selger', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere selger')
        }
      })
  };

  render () {
    const {firstName, lastName, email} = this.state

    return (
      <form onSubmit={e => this.addSubscription(e)}>
        <h1>Endre bruker.</h1>

        <div className="input_wrapper">
          <label htmlFor="firstName">Fornavn:</label>
          <input type="text" className="form-control" id="firstName" aria-describedby="firstNameHelp" placeholder="Navn" value={firstName} onChange={e => this.setState({firstName: e.target.value})} />
        </div>

        <div className="input_wrapper">
          <label htmlFor="lastName">Etternavn:</label>
          <input type="text" className="form-control" id="lastName" aria-describedby="lastNameHelp" placeholder="Bot-nøkkel" value={lastName} onChange={e => this.setState({lastName: e.target.value})} />
        </div>

        <div className="input_wrapper">
          <label htmlFor="email">E-post:</label>
          <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Bot-nøkkel" value={email} onChange={e => this.setState({email: e.target.value})} />
        </div>

        <button className="large">Endre</button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubscription)
