import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import config from '../../config'
import _ from 'lodash'

import Pagination from '../pagination'

class ExtraServicesDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      extraService: {
        name: '',
        botKey: '',
        amount: '0',
        order: '0',
        active: 'false'
      },

      subscriptions: [],
      selectedSubscriptions: [],
      count: 0,
      page: 1,
      perPage: 10,
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/extra-services/${this.props.layout.data.extraServiceId}`)
      .then(res => {
        if (res.data) {
          this.setState({extraService: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })

      this.getSubscriptions()
  }

  getSelectedSubscriptions = () => {
    axios.get(`${config.apiUrl}/extra-services/${this.props.layout.data.extraServiceId}/subscriptions`)
      .then(r => {
        if (r.data) {
          this.setState({selectedsubscriptions: r.data})
        }
      }).catch(e => {
        console.log(e)
      })
  };


  deleteExtraService = () => {
    axios.delete(`${config.apiUrl}/extra-services/${this.props.layout.data.extraServiceId}`)
      .then(res =>{
        window.location = `#/extra-services`
        toastr.success('Slettet tilleggstjeneste')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke slette tilleggstjeneste', e.response.data.message)
          console.error(e.response.data.message)
        }
      })
  };

  updateExtraService = (e) => {
    e.preventDefault()
    const {extraService} = this.state
    axios.put(`${config.apiUrl}/extra-services/${this.props.layout.data.extraServiceId}`, {
      name: extraService.name,
      botKey: extraService.botKey,
      amount: parseInt(extraService.amount),
      order: parseInt(extraService.order),
      active: extraService.active,
    })
      .then(res => {
        toastr.success('tilleggstjeneste oppdatert')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere selger', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere selger')
        }
      })
  };

  getSubscriptions = () => {
    axios.get(`${config.apiUrl}/subscriptions?perPage=500`)
      .then(res => {
        if (res.data.rows) {
          this.setState({subscriptions: res.data.rows, count: res.data.count}, () => this.getSelectedSubscriptions())
        }
      })
      .catch(e => {
        console.error(e)
      })
  };

  handleCheck = (e, id) => {
    if (_.has(this.props.layout, 'data.extraServiceId')) {
      if (e.target.checked) {
        axios.post(`${config.apiUrl}/extra-services/${this.props.layout.data.extraServiceId}/subscriptions`, {id: id})
          .then(res => {
            this.getSubscriptions()
          })
          .catch(e => {
            console.error(e.response)
          })
      } else {
        axios.delete(`${config.apiUrl}/extra-services/${this.props.layout.data.extraServiceId}/subscriptions`, {data: {id: id}})
          .then(res => {
            this.getSubscriptions()
          })
          .catch(e => {
            console.error(e.response)
          })
      }
    }
  };

  paginate = (e, key) => {
    e.preventDefault()
    let page = this.state.page
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page: page}, () => this.getSubscriptions())
  };

  render () {
    const { extraService, subscriptions, selectedsubscriptions } = this.state

    return !extraService ? <div>Loading...</div> : (
      <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="main-body__header">
            <h1>Viser {extraService.name}</h1>
            <a href="#/extraServices">Tilbake</a>
          </div>

          <div style={{marginLeft: '2rem', marginRight: '2rem'}}>
            <h1 className="tm-section__header">Endre tilleggstjenesten</h1>
            <div className="tm-section-card__data add-user">
              <form onSubmit={e => this.updateExtraService(e)} id="update_extraService" className="add-user__form">

                <div className="input_wrapper">
                  <label htmlFor="name">Navn:</label>
                  <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Navn" value={extraService.name} onChange={e => {
                    const copy = Object.assign({}, extraService)
                    copy.name = e.target.value
                    this.setState({extraService: copy})
                  }} />
                  <small id="emailHelp" className="form-text text-muted">Navnet til tilleggstjenesten. Dette vil være synlig i salgskanaler.</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="botKey">Bot-nøkkel:</label>
                  <input type="text" className="form-control" id="botKey" aria-describedby="botKeyHelp" placeholder="Bot-nøkkel" value={extraService.botKey} onChange={e => {
                    const copy = Object.assign({}, extraService)
                    copy.botKey = e.target.value
                    this.setState({extraService: copy})
                  }} />
                  <small id="botKeyHelp" className="form-text text-muted">Nøkkelen som brukes når salg mates inn via BOT</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="amount">Kroner/mnd:</label>
                  <input type="text" min={0} className="form-control" id="amount" aria-describedby="amountHelp" placeholder="0" value={extraService.amount || ''} onChange={e => {
                    const copy = Object.assign({}, extraService)
                    copy.amount = e.target.value
                    this.setState({extraService: copy})
                  }} />
                </div>

                <div className="input_wrapper">
                  <label htmlFor="order">Rekkefølge:</label>
                  <input type="text" min={0} className="form-control" id="order" aria-describedby="orderHelp" placeholder="0" value={extraService.order || ''} onChange={e => {
                    const copy = Object.assign({}, extraService)
                    copy.order = e.target.value
                    this.setState({extraService: copy})
                  }} />
                  <small id="orderHelp" className="form-text text-muted">Sorteringsnøkkel for visning i salgskanaler</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="active">Aktiv:</label>
                  <select className="form-control" value={extraService.active} onChange={e => {
                    const copy = Object.assign({}, extraService)
                    copy.active = e.target.value
                    this.setState({extraService: copy})
                  }}>
                    <option value={true}>Aktiv</option>
                    <option value={false}>Ikke aktiv</option>
                  </select>
                  <small id="active" className="form-text text-muted">Om tilleggstjenesten er aktivt, og skal vises i salgskanaler</small>
                </div>

                <button type="submit">Oppdater</button>
              </form>
              {/*<button class="btn btn-danger small-button" onClick={e => this.deleteExtraService(e)}>Slett</button>*/}
            </div>

            <h1 className="tm-section__header" style={{marginTop: '3rem'}}>Tilleggstjenesten tilhører disse abonnementene</h1>
            <div className="tm-table__header-row">
              <div className={`tm-table__cell`}><span className="cell-content">Aktiv</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Navn</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Bot-nøkkel</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Aktiv</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Trygg</span></div>
            </div>
            <div className="tm-section-card__data add-user">
              {subscriptions && _.sortBy(subscriptions, 'name').map((subscription) => {
                return (
                  <div className="tm-table__row" key={subscription.id}>
                    <div className="tm-table__cell">
                      <div className="input_wrapper">
                        <input type="checkbox"
                          id={subscription.id}
                          checked={_.find(selectedsubscriptions, selectedsubscriptions => selectedsubscriptions.id === subscription.id)}
                          value={subscription.id}
                          onChange={e => this.handleCheck(e, subscription.id)} />
                        <label htmlFor={subscription.id}></label>
                      </div>
                    </div>
                    <div className="tm-table__cell">{subscription.name || 'Ukjent'}</div>
                    <div className="tm-table__cell">{subscription.botKey || 'Ukjent'}</div>
                    <div className="tm-table__cell">{subscription.active ? 'Aktiv' : 'Ikke aktiv'}</div>
                    <div className="tm-table__cell">{subscription.kidSafe ? 'Trygg' : 'Ikke trygg'}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtraServicesDetails)
