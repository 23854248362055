import React, { Component } from 'react'
import { connect } from 'react-redux'

import { unsetModal } from '../../actions/modal'

import AddSubscription from './add-subscription'
import AddExtraService from './add-extra-service'
import AddCampaign from './add-campaign'
import EditUser from './edit-user'

class Modals extends Component {
  constructor(props){
    super(props)
  }

  closeModal = e => {
    e.preventDefault()
    this.props.dispatch(unsetModal())
  };

  render () {
    const { modal } = this.props

    let currentModal = <div />

    return modal && modal.key ? (
      <div className="lightbox_wrapper">
        <div className="lightbox_content">
          <a className="lightbox_content__close-button" href="#" onClick={e => this.closeModal(e)}>Lukk</a>
          {(() => {
            switch(modal.key) {
              case 'add-subscription':
                return <AddSubscription />
              case 'add-extra-service':
                return <AddExtraService />
              case 'add-campaign':
                return <AddCampaign />
              case 'edit-user':
                return <EditUser />
              default:
                return <div />
            }
          })()}
        </div>
      </div>
    ) : <div />
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modals)
