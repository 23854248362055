import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import config from '../../config'
import Pagination from '../pagination'
import _ from 'lodash'

class RolesDetailsComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      role: null,
      permissions: [],
      selectedRoles: [],

      count: 0,
      page: 1,
      perPage: 10,
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/roles/${this.props.layout.data.roleId}`)
      .then(res => {
        if (res.data) {
          this.setState({role: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })

    this.getPermissions()
  }

  getPermissions = () => {
    let queryString = '?'

    // handle page
    if (this.state.page) {
      queryString += `&page=${this.state.page}`
    }

    if (this.state.perPage) {
      queryString += `&perPage=${this.state.perPage}`
    }

    axios.get(`${config.apiUrl}/permissions${queryString}`)
      .then(res => {
        if (res.data.rows) {
          this.setState({permissions: res.data.rows, count: res.data.count}, () => this.getSelectedPermissions())
        }
      })
      .catch(e => {
        console.error(e)
      })
  };

  getSelectedPermissions = () => {
    axios.get(`${config.apiUrl}/roles/${this.props.layout.data.roleId}/permissions`)
      .then(res => {
        if (res.data) {
          this.setState({selectedPermissions: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })
  };

  paginate = (e, key) => {
    e.preventDefault()
    let page = this.state.page
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page: page}, () => this.getPermissions())
  };

  handleCheck = (e, id) => {
    if (_.has(this.props.layout, 'data.roleId')) {
      if (e.target.checked) {
        axios.post(`${config.apiUrl}/roles/${this.props.layout.data.roleId}/permissions`, {id: id})
          .then(res => {
            this.getPermissions()
          })
          .catch(e => {
            console.error(e.response)
          })
      } else {
        axios.delete(`${config.apiUrl}/roles/${this.props.layout.data.roleId}/permissions`, {data: {id: id}})
          .then(res => {
            this.getPermissions()
          })
          .catch(e => {
            console.error(e.response)
          })
      }
    }
  };

  render () {
    const { role, permissions, selectedPermissions } = this.state

    return !role ? <div>Loading...</div> : (
      <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="main-body__header">
            <h1>Viser {role.name}</h1>
            <a href="#/departments">Tilbake</a>  
          </div>

          <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.permissions} />

          <div className="tm-section-card">
            <h1 className="tm-section-card__header">Rettigheter</h1>
            <div className="tm-section-card__data">
              <div className="tm-table tm-table--narrow">
                <div className="tm-table__header-row">
                  <div className="tm-table__cell">Modus</div>
                  <div className="tm-table__cell">Rettighet</div>
                  <div className="tm-table__cell">Rettighets nøkkel</div>
                </div>
                <div className="tm-table__body">
                  {permissions && permissions.map((permission) => {
                    return (
                      <div className="tm-table__row" key={permission.id}>
                        <div className="tm-table__cell">
                          <div className="input_wrapper">
                            <input type="checkbox" 
                              id={permission.id}
                              checked={_.find(selectedPermissions, selectedPermissions => selectedPermissions.id === permission.id)} 
                              value={permission.id}
                              onChange={e => this.handleCheck(e, permission.id)} />
                            <label htmlFor={permission.id}></label>
                          </div>
                        </div>
                        <div className="tm-table__cell">{permission.name || 'Ukjent'}</div>
                        <div className="tm-table__cell">{permission.key || 'Ukjent'}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.permissions} />

        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const RolesDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesDetailsComponent);

export default RolesDetail