import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import moment from 'moment'

import config from '../../config'

const labelMap = {
  first_name: 'Fornavn',
  last_name: 'Etternavn',
  email: 'E-post',
  phone: 'Telefon',
  address: 'Adresse',
  postal_area: 'Poststed',
  postal_code: 'Postnummer',
  sim_type: 'SIM-type',
  sim_number: 'SIM-nummer',
  checkboxes: 'Checkbokser',
  childs_name: 'Barnets navn',
  porting_date: 'Porteringsdato',
  campaign_name: 'Kampanjenavn',
  extra_services: 'Tilleggstjenester',
  combined_invoice: 'Samlefaktura',
  subscription_name: 'Produktnavn',
  childs_date_of_birth: 'Barnets fødselsdag',
  sign_method: 'Signeringsmetode',
  sign_value: 'Signatur',
  lookup_method: 'Oppslagstype',
  latitude: 'Breddegrad',
  longitude: 'Lengdegrad',
  source_type: 'Salgskanal',
}

class SaleDetailsComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      sale: null,
    }
  }

  componentDidMount() {

    axios.get(`${config.newApiUrl}/sales/${this.props.layout.data.saleId}`)
      .then(res => {
        if (res.data) {
          this.setState({sale: res.data.data})
        }
      })
      .catch(e => {
        console.error(e)
      })

    // axios.get(`${config.apiUrl}/sales/${this.props.layout.data.saleId}/bot-screenshots`)
    //   .then(res => {
    //     if (res.data) {
    //       this.setState({botScreenshots: res.data})
    //     }
    //   })
    //   .catch(e => {
    //     console.error(e)
    //   })

    // axios.get(`${config.apiUrl}/sales/${this.props.layout.data.saleId}/seller`)
    //   .then(r => {
    //     if (r.data) {
    //       this.setState({seller: r.data})
    //     }
    //   })
    //   .catch(e => console.error(e))
  }

  // retryBot = (e) => {
  //   axios.post(`${config.apiUrl}/retry-bot/${this.props.layout.data.saleId}`)
  //     .then(r => {
  //       console.log('worked', true)
  //       window.location.reload()
  //     })
  //     .catch(e => {
  //       toastr.error('Kunne ikke kjøre bot på nytt', e.response.data.message)
  //       console.error(e)
  //     })
  // };

  renderValue = (key, value) => {
    const dateFormat = 'D.MM.YYYY'
    const dateTimeFormat = 'D.MM.YYYY hh:mm:ss'

    switch (key) {
      case 'date_of_birth':
      case 'porting_date':
        if (value) {
          return moment(value).format(dateFormat)
        }
        return value
      case 'created_at':
      case 'updated_at':
      case 'deleted_at':
        if (value) {
          return moment(value).format(dateTimeFormat)
        }
        return value
      case 'sign_value':
        if (value && value.startsWith('data:')) {
          return <img alt={key} src={value} />
        }
        return value
      case 'reported':
      case 'botSuccess':
        return value === true ? 'ja' : 'nei'
      default:
        return value
    }
  };

  renderLabel = ( key ) => {
    return labelMap[key] ? labelMap[key] : key
  };

  render () {
    const { sale } = this.state
    const renderValue = this.renderValue
    let content = <div>Loading...</div>
    console.log('sale', sale)

    if (sale && sale.id) {
      content = (
         <main>
          <div className={`main-body --wide-layout-temp-class --single-sales ${(sale.botSuccess === false && sale.reported === false) && 'tm-table__row--emphasized'}`}>
            <div className="tm-section-card">
              <h1>Salg til {sale.first_name} {sale.last_name}, {renderValue('created_at', sale.created_at)}</h1>

              <h2>Informasjon om ordren</h2>
              <div style={{display: 'flex'}}>
                <div className="tm-table tm-table--narrow tm-section-card__data">
                    {sale && sale.order && ['source_type', 'lookup_method', 'sign_method', 'sign_value'].map(function (key) {
                      return (
                        <div className="tm-table__row" key={`sale-${key}`}>
                          <div className="tm-table__cell">{labelMap[key] ? labelMap[key] : key}</div>
                          <div className="tm-table__cell">{renderValue(key, sale.order[key])}</div>
                        </div>
                      )
                    })}
                    {sale.order && sale.order.latitude && sale.order.longitude &&
                      <React.Fragment>
                        <div className="tm-table__row">
                          <div className="tm-table__cell">Lengdegrad</div>
                          <div className="tm-table__cell">{sale.order.longitude}</div>
                        </div>
                        <div className="tm-table__row">
                          <div className="tm-table__cell">Breddegrad</div>
                          <div className="tm-table__cell">{sale.order.latitude}</div>
                        </div>
                      </React.Fragment>
                    }
                    {/*
                    <div className="tm-table__row">
                      <div className="tm-table__cell">Importstreng</div>
                      <div className="tm-table__cell"><textarea style={{width: '100%', height: '100px'}} disabled>{sale.lastSalesStr}</textarea></div>
                    </div>
                    */}
                </div>
                {sale.order && sale.order.latitude && sale.order.longitude &&
                  <div style={{marginLeft: '2rem', marginBottom: '2rem'}}>
                    {/*
                    <img src="//via.placeholder.com/600x400" />
                    */}
                    <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${sale.order.latitude},${sale.order.longitude}&zoom=16`}>
                      <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${sale.order.latitude},${sale.order.longitude}&zoom=16&size=600x400&maptype=roadmap&markers=color:red%7C${sale.order.latitude},${sale.order.longitude}&key=${config.staticMapsKey}`} />
                    </a>
                  </div>
                }
              </div>

              <h2>Informasjon om salget</h2>
              <div className="tm-table tm-table--narrow tm-section-card__data">
                  <div className="tm-table__row">
                    <div className="tm-table__cell">Månedspris</div>
                    <div className="tm-table__cell">
                      {sale.order && sale.order.derived_data && sale.order.derived_data.submitted_monthly_price &&
                        <span>{sale.order.derived_data.submitted_monthly_price},-</span>
                      }
                      {(!sale.order || !sale.order.derived_data || !sale.order.derived_data.submitted_monthly_price) &&
                        <span>-</span>
                      }
                    </div>
                  </div>
                  {sale && sale.campaigns && Boolean(sale.campaigns.length) &&
                    <div className="tm-table__row" key={`sale-campaigns`}>
                      <div className="tm-table__cell">Kampanjer</div>
                      <div className="tm-table__cell inline-list">
                        {sale.campaigns.map(c => <span>{c.name}</span>)}
                      </div>
                    </div>
                  }
                  {sale && sale.product_options && Boolean(sale.product_options.length) &&
                    <div className="tm-table__row" key={`sale-product-options`}>
                      <div className="tm-table__cell">Ekstratjenester</div>
                      <div className="tm-table__cell inline-list">
                        {sale.product_options.map(o => <span>{o.name}</span>)}
                      </div>
                    </div>
                  }
                  {sale && ['sim_type', 'sim_number', 'porting_date'].map(function (key) {
                    return (
                      <div className="tm-table__row" key={`sale-${key}`}>
                        <div className="tm-table__cell">{labelMap[key] ? labelMap[key] : key}</div>
                        <div className="tm-table__cell">{renderValue(key, sale[key])}</div>
                      </div>
                    )
                  })}
                  {/*
                  <div className="tm-table__row">
                    <div className="tm-table__cell">Importstreng</div>
                    <div className="tm-table__cell"><textarea style={{width: '100%', height: '100px'}} disabled>{sale.lastSalesStr}</textarea></div>
                  </div>
                  */}
              </div>

              <h2>Informasjon om kjøper</h2>
              <div className="tm-table tm-table--narrow tm-section-card__data">
                  {sale && ['first_name', 'last_name', 'email', 'phone', 'address', 'postal_area', 'postal_code'].map(function (key) {
                    return (
                      <div className="tm-table__row" key={`buyer-${key}`}>
                        <div className="tm-table__cell">{labelMap[key] ? labelMap[key] : key}</div>
                        <div className="tm-table__cell">{renderValue(key, sale[key])}</div>
                      </div>
                    )
                  })}
              </div>

              <h2>Informasjon om selger</h2>
              <div className="tm-table tm-table--narrow tm-section-card__data">
                {sale && sale.department &&
                  <div className="tm-table__row" key={`seller-dept-name`}>
                    <div className="tm-table__cell">Salgskontor</div>
                    <div className="tm-table__cell">{sale.department.name}</div>
                  </div>
                }
                {sale && sale.seller && ['first_name', 'last_name', 'email', 'phone'].map(function (key) {
                  return (
                    <div className="tm-table__row" key={`seller-${key}`}>
                      <div className="tm-table__cell">{labelMap[key] ? labelMap[key] : key}</div>
                      <div className="tm-table__cell">{renderValue(key, sale.seller[key])}</div>
                    </div>
                  )
                })}
              </div>

              {/*
                <h2>Skjermbilder bot</h2>
                <div className="tm-table tm-table--narrow tm-section-card__data">
                  <div className="tm-table__row">
                    <div className="tm-table__cell">Bot</div>
                    <div className="tm-table__cell">
                      <div>
                        {botScreenshots && botScreenshots.length > 0 && botScreenshots.map(screenshot => (
                          <div key={screenshot.id} className="tm-screenshot">
                            <img src={`data:image/png;base64,${screenshot.image}`} alt={screenshot.id} />
                          </div>
                        ))}
                        <div>
                          <button className="btn btn-primary" onClick={e => this.retryBot(e)}>Prøv automatisk import på nytt</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              */}
            </div>
          </div>
        </main>
      )
    }
    return content
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const SaleDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaleDetailsComponent);

export default SaleDetails
