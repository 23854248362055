import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'

const debug = false

class RenderColumn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props)
      this.getName()
  }

  componentDidMount() {
    this.getName()
  }

  getName = () => {
    // console.log('get name')
    const {item} = this.props
    let resourceString

    if (item.sellerId) {
      resourceString = `/users/${item.sellerId}`
    } else {
      resourceString = `/departments/${item.departmentId}`
    }


    axios.get(`${config.apiUrl}${resourceString}`)
      .then(res => {
        if (res.data) {
          if (res.data.firstName || res.data.lastName) {
            this.setState({name: `${res.data.firstName} ${res.data.lastName}`})
          } else if (res.data.username) {
            this.setState({name: res.data.username})
          } else if (res.data.name) {
            this.setState({name: res.data.name})
          }
        }
      })
      .catch(e => {
        console.error(e)
      })
  };

  render () {
    const {item, list, index} = this.props
    const {name} = this.state

    const total = list.length

    const width = 100 / total

    const height = item.count / list[0].count * 100

    return (
      <div style={{
        position: 'absolute',
        bottom: 0, 
        left: `${width * index}%`,
        width: `${width}%`, 
        height: `${height}%`,
        background: debug ? 'yellow' : ''}}>
        <div syle={{height: '100%', width: '100%', position: 'relative'}}>
          <div style={{position: 'absolute', top: 5, right: 5, bottom: 5, left: 6, background: '#A1D078'}}>
            <div style={{position: 'absolute', top: 10, right: 10, left: 10, textAlign: 'center', color: 'white'}}>Icon</div>
            <div style={{position: 'absolute', bottom: 10, right: 10, left: 10, textAlign: 'center'}}>
              <div style={{color: 'white'}}>{item.count}</div>
              <div style={{color: 'white'}}>salg</div>
            </div>
            <div style={{position: 'absolute', bottom: -80, left: 0, right: 0, textAlign: 'center', height: 60}}>{name}</div>
          </div>
        </div>
      </div>
    )
  }
}

class StatisticsListComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      list: [],

      departments: [],

      department: 'all',
      view: 'sellers',
      period: 'week',
      amount: 10,
    }
  }

  componentDidMount() {
    // get top level departments
    axios.get(`${config.apiUrl}/departments?order=name`)
      .then(res => {
        if (res.data.rows) {
          this.setState({departments: res.data.rows, loading: false})
        }
      })
      .catch(e => {
        console.error(e)
      })

    this.getStatistics()
  }

  getStatistics = () => {
    let queryString = '?'
    const {department, view, period, amount} = this.state

    if (department) {
      queryString += `&department=${department}`
    }

    if (view) {
      queryString += `&view=${view}`
    }

    if (amount) {
      queryString += `&amount=${amount}`
    }

    if (period) {
      queryString += `&period=${period}`
    }

    axios.get(`${config.apiUrl}/get-statistics${queryString}`)
      .then(res => {
        if (res.data) {
          this.setState({list: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })
  };

  render () {
    const {view, period, amount, departments, department, list } = this.state

    // console.log('list', list)

    let total = 0
    if (list) {
      list.forEach(item => total += parseInt(item.count))
    }

    return (
      <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="tm-section-card">
            <h1 className="tm-section-card__header">Statistikk</h1>
            
            <div>
              <h3>Rad 1: Velg selgere eller avdelinger</h3>
              <select onChange={e => this.setState({view: e.target.value}, () => this.getStatistics())} value={view}>
                <option value="sellers">Selgere</option>
                <option value="departments">Avdelinger</option>
              </select>
            </div>

            <div>
              <h3>Rad 2: Filtrer</h3>
              <hr />

              <h4>Periode:</h4>
              <select onChange={e => this.setState({period: e.target.value}, () => this.getStatistics())} value={period}>
                <option value="all">All time</option>
                <option value="year">Hitill i år</option>
                <option value="week">Hitill denne uken</option>
                <option value="day">Hitill i dag</option>
              </select>

              <h4>Antall:</h4>
              <div className="input_wrapper">
                <input type="checkbox"
                       value={3}
                       id="three"
                       checked={amount === 3}
                       onChange={e => this.setState({amount: 3}, () => this.getStatistics())} />
                <label htmlFor="three"><span>Vis topp 3</span></label>
              </div>

              <div className="input_wrapper">
                <input type="checkbox"
                       value={10}
                       id="ten"
                       checked={amount === 10}
                       onChange={e => this.setState({amount: 10}, () => this.getStatistics())} />
                <label htmlFor="ten"><span>Vis topp 10</span></label>
              </div>

              {view === 'sellers' && 
                <div>
                  <h4>Avdelinger:</h4>

                  <select onChange={e => this.setState({department: e.target.value}, () => this.getStatistics())} value={department}>
                    <option value="all">--Alle--</option>
                    {departments.map(department => (
                      <option value={department.id}>{department.name}</option>
                    ))}
                  </select>
                </div>
              }
            </div>

            <div>
              <h3>Vis graf og info</h3>

              <p>Totalt {total} hitill {period === 'week' && 'denne uken'} {period === 'day' && 'i dag'} {period === 'year' && 'i år'} {period === 'all' && 'All time'}</p>

              {/* diagram */}
              <div style={{height: '500px', background: debug ? '#d3d3d3' :'', position: 'relative'}}>
                {list.map((item, index) => <RenderColumn item={item} index={index} list={list} key={`column-${index}`} />)}
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => { 
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsListComponent);