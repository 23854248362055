import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import cookies from 'js-cookie'
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import config from '../../config'
import moment from 'moment'

import Pagination from '../pagination'

class SalesListComponent extends Component {
  constructor(props){
    super(props)

    const today = moment()
    const minus30days = moment().subtract(7, 'days')
    const start = moment(minus30days).startOf('day')
    const end = moment(today).endOf('day')

    this.state = {
      sales: [],
      count: 0,
      page: 1,
      perPage: 50,
      subscriptions: [],
      departments: [],

      showExtendedFilters: false,

      loading: true,

      orderBy: {
        column: null,
        order: null,
      },

      selectedFilters: {
        from: start.toISOString(),
        to: end.toISOString(),
        fromToKey: 'created',
        product: [],
        campaign: [],
        childsName: false,
        searchString: '',
        onlyFailed: false,
      },

      filterValues: {},
    }
  }

  getExistingFiltersFromCookie = (cb) => {
    const selectedFilters = cookies.getJSON('TMFilters')
    if (selectedFilters) {
      this.setState({selectedFilters: selectedFilters}, () => cb())
    } else {
      cb()
    }
  };

  setFilterCookie = (cb) => {
    // console.log('this.state.selectedFilters', this.state.selectedFilters)
    cookies.set('TMFilters', this.state.selectedFilters, {expires: 0.2})
    cb()
  };

  componentDidMount() {
    this.getExistingFiltersFromCookie(() => {
      this.getResource('subscriptions')
      this.getResource('departments')
      this.getSales((sales) => this.setState({filteredSales: sales}))
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state && prevState.loaded) {
      if (this.state.forceUpdate !== prevState.forceUpdate) {
        this.getSales()
      }
    }
  }

  getResource = (key) => {
    axios.get(`${config.newApiUrl}/${key}?page_size=100`)
      .then(r => {
        if (r.data) {
          console.log('getResource', key, r.data.data)
          this.setState({[key]: r.data.data})
        } else {
          console.log('no ', key)
        }
      })
      .catch(e => console.log('Could not fetch ', key, ' ',  e))
  };

  getSales = debounce((cb) => {
    // const _this = this;
    this.setState({loading: true})

    let queryString = '?'

    // Get ordering
    if (this.state.orderBy.column && this.state.orderBy.order) {
      queryString += `&order_by=${this.state.orderBy.column}&order=${this.state.orderBy.order}`
    }

    // get searchstring
    if (this.state.selectedFilters.searchString) {
      queryString += `&q=${this.state.selectedFilters.searchString}`
      queryString += `&fields=first_name,last_name,email,phone,sub_name,dept_name`
    }

    // only show failed
    if (this.state.selectedFilters.onlyFailed) {
      queryString += '&botSuccess=false'
    }

    // handle from/to filter
    if (this.state.selectedFilters.from && this.state.selectedFilters.to) {
      queryString += `&from=${this.state.selectedFilters.from}&to=${this.state.selectedFilters.to}&date_key=${this.state.selectedFilters.fromToKey}`
    }

    // handle page
    if (this.state.page) {
      queryString += `&page=${this.state.page}`
    }

    if (this.state.perPage) {
      queryString += `&page_size=${this.state.perPage}`
    }

    // Get all sales
    axios.get(`${config.newApiUrl}/sales${queryString}`)
      .then(r => {
        if (r.data) {
          this.setState({sales: r.data.data, count: r.data.meta.pagination.total_entries, loading: false})
        }
      }).catch(e => {
        console.log('error fetching sales', e)
      })
  }, 150);

  updateSelectedFilters = (filterKey, selectedFilters) => {
    const currentSelectedFilters = {...this.state.selectedFilters}
    currentSelectedFilters[filterKey] = selectedFilters
    this.setState({selectedFilters: currentSelectedFilters}, () => this.setFilterCookie(() => this.getSales()))
  };

  toggleOrderBy = (key) => {
    // 1. If column is not set OR not right key, then set it to key AND 'ASC'
    if (!this.state.orderBy.column || this.state.orderBy.column !== key) {
      const orderBy = {...this.state.orderBy}
      orderBy.column = key
      orderBy.order = 'asc'
      this.setState({orderBy: orderBy}, () => this.getSales())
    }
    // 2. Else if column is key but asc is set, then set desc
    else if (this.state.orderBy.column === key && this.state.orderBy.order === 'asc') {
      const orderBy = {...this.state.orderBy}
      orderBy.order = 'desc'
      this.setState({orderBy: orderBy}, () => this.getSales())
    }
    // 3. REMOVE ORDER
    else {
      this.setState({orderBy: {column: null, order: null}}, () => this.getSales())
    }
  };

  getOrderedSales = (sales) => {
    // return _.orderBy(sales, [this.state.orderBy.column],[this.state.orderBy.order]);
  };

  getColumnClass = (key) => {
    if (this.state.orderBy.column === key) {
      if (this.state.orderBy.order === 'asc') {
        return 'is-order-asc'
      } else {
        return 'is-order-desc'
      }
    }
    return ''
  };

  paginate = (e, key) => {
    e.preventDefault()
    let page = this.state.page
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page: page}, () => this.getSales())
  };

  render () {
    const {sales, count, subscriptions, departments} = this.state

    return (
       <main>
        <div className="main-header">
          <div className="tm-filters">
            <div className="filter-group date-filter">
              <label className="filter-group__header">Datofilter</label>
              <DatePicker
                className="form-control"
                selected={moment(this.state.selectedFilters.from)}
                onChange={date => this.updateSelectedFilters('from', date.toISOString())}
                dateFormat="DD/MM/YYYY" />
              <div className="date-filter__separator">-</div>
              <DatePicker
                className="form-control"
                selected={moment(this.state.selectedFilters.to)}
                onChange={date => this.updateSelectedFilters('to', date.toISOString())}
                dateFormat="DD/MM/YYYY" />
            </div>

            <div className="filter-group">
              <label htmlFor="search_string">Søk (navn/tlfnr/epost/prisplan/salgskontor)</label>
              <input type="text" id="search_string" className="form-control" value={this.state.selectedFilters.searchString} onChange={e => this.updateSelectedFilters('searchString', e.target.value)} />
            </div>

            <div className="filter-group filter-group--vertical">
              {/*
              <div>
                <input type="checkbox" id="childs_name" className="checkbox" checked={this.state.selectedFilters && this.state.selectedFilters.onlyChildSubscriptions} onChange={e => this.updateSelectedFilters('onlyChildSubscriptions', e.target.checked)} />
                <label htmlFor="childs_name"><span>Vis abonnementer med barn</span></label>
              </div>
              */}
              {/*
              <div>
                <input type="checkbox" id="bot_success" className="checkbox" checked={this.state.selectedFilters && this.state.selectedFilters.onlyFailed} onChange={e => this.updateSelectedFilters('onlyFailed', e.target.checked)} />
                <label htmlFor="bot_success"><span>Vis kun feilet</span></label>
              </div>
              */}
            </div>
          </div>
        </div>


          <div className="main-body --wide-layout-temp-class">
            <div className="main-body__header">
              <h1>Viser {sales.length} av {count} salg</h1>
              <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.sales} />
            </div>
            <div className={`tm-table h-has-loader ${this.state.loading ? 'is-loading' : 'is-done-loading'}`}>
              <div className="lds_w"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
              <div className="tm-table__header-row">
                <div className={`tm-table__cell -sortable ${this.getColumnClass('first_name')}`} onClick={() => this.toggleOrderBy('first_name')}><span className="cell-content">Fornavn</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('last_name')}`} onClick={() => this.toggleOrderBy('last_name')}><span className="cell-content">Etternavn</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('email')}`} onClick={() => this.toggleOrderBy('email')}><span className="cell-content">E-post</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('phone')}`} onClick={() => this.toggleOrderBy('phone')}><span className="cell-content">Telefonnr</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('sub_name')}`} onClick={() => this.toggleOrderBy('sub_name')}><span className="cell-content">Prisplan</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('dept_name')}`} onClick={() => this.toggleOrderBy('dept_name')}><span className="cell-content">Salgskontor</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('created')}`} onClick={() => this.toggleOrderBy('created')}><span className="cell-content">Opprettet</span></div>
              </div>
              <div className="tm-table__body">
                {sales.map((s) => {
                  let subName = 'Ukjent'
                  if (s.subscription_id) {
                    const sub = subscriptions.find(sub => sub.id === s.subscription_id)
                    if (sub) {
                      subName = sub.name
                    }
                  }
                  const firstName = s.first_name || 'Ukjent'
                  const lastName = s.last_name || 'Ukjent'
                  const email = s.email || 'Ukjent'
                  const phone = s.phone || 'Ukjent'
                  return (
                    <a className="tm-table__row"
                        key={s.id}
                        href={`/#/sales/${s.id}`}>
                      <div className="tm-table__cell"><div className="cell-content">{firstName}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{lastName}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{email}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{phone}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{subName}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.department_id && _.find(departments, dep => dep.id === s.department_id) && _.find(departments, dep => dep.id === s.department_id).name}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.created_at && moment(s.created_at).format('DD/MM/YYYY')}</div></div>
                    </a>
                  )
                })}
                {sales.length === 0 &&
                  <div>Ingen salg med dette søket</div>
                }
              </div>
            </div>
            <div className="main-body__footer">
              <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.sales} />
            </div>
          </div>


        </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const SalesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesListComponent);

export default SalesList
