import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import { toastr } from 'react-redux-toastr'

import Logo from '../logo'

import config from '../../config'
import { checkIfLoggedIn } from '../../auth'

class AnonComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identity: '',
      password: '',
    }
  }

  signIn = (e) => {
    e.preventDefault()
    axios.post(`${config.apiUrl}/authenticate`, this.state)
      .then(r => {
        if (r.data.data.token) {
          const myStorage = window.localStorage
          myStorage.setItem('token', `Bearer ${r.data.data.token}`)
          checkIfLoggedIn({ dispatch: this.props.dispatch })
        } else {
          toastr.error('Klarte ikke å logge deg inn.', 'Prøv igjen eller kontakt leverandør')
        }
      })
      .catch(e => {
        toastr.error('Klarte ikke å logge deg inn.', 'Prøv igjen eller kontakt leverandør')
      })
  };

  render() {
    return (
      <main className="anon">
        <div className="anon_inner">
          <div className="form_wrapper">
            <Logo />
            <form onSubmit={e => this.signIn(e)}>
              <div className="input_wrapper">
                <input type="text" placeholder="Navn/Epost/telefon" required value={this.state.identity} onChange={e => {
                  this.setState({ identity: e.target.value })
                }} />
              </div>
              <div className="input_wrapper">
                <input type="password" placeholder="Passord" required value={this.state.password} onChange={e => {
                  this.setState({ password: e.target.value })
                }} />
              </div>
              <button className="login">Logg inn</button>
            </form>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    //anon: state.anon,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const Anon = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnonComponent);

export default Anon
