import React from 'react'
import { connect } from 'react-redux'
import { logOut } from '../../auth'
import _ from 'lodash'

import Logo from '../logo'

import {
  setSalesList,
  setActivity,
  setUkraineSalesList,
  setDepartmentsList,
  setUsersList,
  setRolesList,
  setStatisticsList,
  setSubscriptionsList,
  setExtraServicesList,
  setCampaignsList,
  setStandsList,
} from '../../actions/layout'

const checkIfActive = (route, key) => {
  if (route === key) {
    return 'site-menu__item is-active'
  } else {
    return 'site-menu__item'
  }
}

const HeaderComponent = ({ dispatch, layout, user }) => (
  <header className="site-header site-header--alternative">
    <div className="site-header__inner">

      <a href="/" className="tm-logo-link">
        <Logo />
      </a>

      <nav className="site-nav">
        <ul className="site-menu">
          <li className={checkIfActive(layout.route, 'SALES_LIST')}>
            <a className="site-menu__anchor" href="" onClick={(e) => {
              e.preventDefault()
              dispatch(setSalesList())
            }}>Salg</a>
          </li>
          {user.roles.find(r => r.name === 'admin') &&
            <li className={checkIfActive(layout.route, 'UKRAINE_SALES_LIST')}>
              <a className="site-menu__anchor" href="" onClick={(e) => {
                e.preventDefault()
                dispatch(setUkraineSalesList())
              }}>Ukraina-ordrer</a>
            </li>
          }
          {user.roles.find(r => r.name === 'admin') &&
            <li className={checkIfActive(layout.route, 'ACTIVITY')}>
              <a className="site-menu__anchor" href="" onClick={(e) => {
                e.preventDefault()
                dispatch(setActivity())
              }}>Aktivitet</a>
            </li>
          }
          {user.roles.find(r => r.name === 'admin') &&
            <li className={checkIfActive(layout.route, 'SUBSCRIPTIONS_LIST')}>
              <a className="site-menu__anchor" href="" onClick={(e) => {
                e.preventDefault()
                dispatch(setSubscriptionsList())
              }}>Prisplaner</a>
            </li>
          }
          {user.roles.find(r => r.name === 'admin') &&
            <li className={checkIfActive(layout.route, 'CAMPAIGNS_LIST')}>
              <a className="site-menu__anchor" href="" onClick={(e) => {
                e.preventDefault()
                dispatch(setCampaignsList())
              }}>Kampanjer</a>
            </li>
          }
          {user.roles.find(r => r.name === 'admin') &&
            <li className={checkIfActive(layout.route, 'EXTRA_SERVICES_LIST')}>
              <a className="site-menu__anchor" href="" onClick={(e) => {
                e.preventDefault()
                dispatch(setExtraServicesList())
              }}>Tilleggstjenester</a>
            </li>
          }
          { /*
        {_.find(user.roles, role => role.name === 'admin') &&
          <li className={checkIfActive(layout.route, 'USERS_LIST')}>
            <a className="site-menu__anchor" href="" onClick={(e) => {
              e.preventDefault()
              dispatch(setUsersList())
            }}>Personer</a>
          </li>
        }
        <li className={checkIfActive(layout.route, 'STATISTICS_LIST')}>
          <a className="site-menu__anchor" href="" onClick={(e) => {
            e.preventDefault()
            dispatch(setStatisticsList())
          }}>Statistikk</a>
        </li>
        {_.find(user.roles, role => role.name === 'admin') &&
          <li className={checkIfActive(layout.route, 'ROLES_LIST')}>
            <a className="site-menu__anchor" href="" onClick={(e) => {
              e.preventDefault()
              dispatch(setRolesList())
            }}>Roller</a>
          </li>
        }
      */ }
          <li className={checkIfActive(layout.route, 'STANDS_LIST')}>
            <a className="site-menu__anchor" href="" onClick={(e) => {
              e.preventDefault()
              dispatch(setStandsList())
            }}>Stands</a>
          </li>
          <li className={checkIfActive(layout.route, 'DEPARTMENTS_LIST')}>
            <a className="site-menu__anchor" href="" onClick={(e) => {
              e.preventDefault()
              dispatch(setDepartmentsList())
            }}>Teams og selgere</a>
          </li>
          {user.roles.find(r => r.name === 'admin') &&
            <li>
              <a className="site-menu__anchor" href="/dashboards/" target="_blank">Dashboards</a>
            </li>
          }
        </ul>

        <button className="logout" onClick={() => logOut(dispatch)}>Logg ut</button>
      </nav>

    </div>
  </header>
)

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);

export default Header
