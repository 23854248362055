import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import config from '../../config'
import _ from 'lodash'

import { setModal } from '../../actions/modal'

const UsersList = ({users, roles, deleteUser, resetPassword, dispatch, updateDepartmentsUsersRole}) => (
  <div className="tm-table tm-table--narrow">
    <div className="tm-table__header-row">
      <div className="tm-table__cell">Brukernavn</div>
      <div className="tm-table__cell">Fornavn og etternavn</div>
      <div className="tm-table__cell">Epost</div>
      <div className="tm-table__cell">Telefon</div>
      <div className="tm-table__cell">Rolle</div>
      <div className="tm-table__cell --change-password">Endre password</div>
      <div className="tm-table__cell --align-right">Slett</div>
    </div>
    <div className="tm-table__body">
      {users.map((user) => {
        const role = _.find(roles, role => user.departmentsUsers.roleId === role.id)
        return (
          <div className="tm-table__row" key={user.id}>
            <div className="tm-table__cell">{user.username || ''}</div>
            <div className="tm-table__cell">{user.firstName} {user.lastName} {!user.firstName && !user.lastName && ''}</div>
            <div className="tm-table__cell">{user.email || ''}</div>
            <div className="tm-table__cell">{user.phone || ''}</div>
            <div className="tm-table__cell">
              <select value={role.id} onChange={e => updateDepartmentsUsersRole(e, user.id)}>
                {roles.map(role => <option value={role.id}>{role.name}</option>)}
              </select>
             </div>
            <div className="tm-table__cell --change-password">
              {user.email && <button onClick={e => resetPassword(user.email)}>Reset passord</button>}
              {!user.email && 'Legg til epost for å endre passord'}
            </div>
            <div className="tm-table__cell">
              <button onClick={e => dispatch(setModal({key: 'edit-user', userId: user.id}))}>Endre</button>
            </div>
            <div className="tm-table__cell --align-right">
              <button className="btn btn-danger small-button" onClick={(e) => deleteUser(user.id)}>Slett</button>
            </div>
          </div>
        )
      })}
      {users.length === 0 &&
        <div style={{padding: '15px'}}>Ingen registrerte selgere</div>
      }
    </div>
  </div>
)

class OfficesDetailsComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      department: null,
      roles: [],
      users: null,
      email: '',
      role: null,
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/departments/${this.props.layout.data.departmentId}`)
      .then(res => {
        if (res.data) {
          this.setState({department: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })

    axios.get(`${config.apiUrl}/roles`)
      .then(res => {
        if (res.data.rows) {
          // remove admin from roles unless you are admin allready
          const isAdmin = _.find(this.props.user.roles, role => role.name === 'admin')
          let roles = res.data.rows

          if (!isAdmin)
            roles = roles.filter(role => role.name !== 'admin')

          this.setState({roles: roles}, () => {
            this.setState({role: this.state.roles[0].id})
          })
        }
      })
      .catch(e => {
        console.error(e)
      })

    this.getUsers()
  }

  getUsers = () => {
    axios.get(`${config.apiUrl}/departments/${this.props.layout.data.departmentId}/users`)
      .then(res => {
        if (res.data) {
          this.setState({users: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })
    };


  addUser = (e) => {
    e.preventDefault()
    const departmentId = this.state.department.id

    const data = {
      email: this.state.email,
      roleId: this.state.role,
      departmentId: departmentId
    }

    axios.post(`${config.apiUrl}/create-department-user`, data)
      .then(res => {
        this.getUsers()
        toastr.success('Selger lagt til')
        this.setState({email: ''})
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke legge til selger', e.response.data.message)
          console.error(e.response)
        }
      })
  };

  deleteUser = (userId) => {
    const departmentId = this.state.department.id
    axios.delete(`${config.apiUrl}/departments/${departmentId}/users`, {data: {id: userId}})
      .then(res =>{
        this.getUsers()
        toastr.success('Selger slettet fra avdeling')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke slette selger', e.response.data.message)
          console.error(e.response.data.message)
        }
      })
  };

  resetPassword = (email) => {
    axios.post(`${config.apiUrl}/reset-password`, {identity: email})
      .then(res => {
        toastr.success('Reset passord e-post sendt til bruker')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere selger', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere selger')
        }
      })
  };

  updateDepartmentsUsersRole = (e, userId) => {
    axios.post(`${config.apiUrl}/change-departments-users-role`, {roleId: e.target.value, userId: userId, departmentId: this.state.department.id})
      .then(res => {
        toastr.success('Rolle endret på brukeren')
        this.getUsers()
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere selger', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere selger')
        }
      })
  }

  render () {
    const { department, users, roles } = this.state
    const { dispatch } = this.props

    return !department ? <div>Loading...</div> : (
      <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="main-body__header">
            <h1>Viser {department.name}</h1>
            <a href="#/departments">Tilbake</a>
          </div>

          <div className="tm-section-card">
            <h1 className="tm-section__header">Legg til bruker</h1>
            <div className="tm-section-card__data add-user">
              <form onSubmit={e => this.addUser(e)} id="add_user" className="add-user__form">
                <div className="input_wrapper">
                  <label htmlFor="email">Epost:</label>
                  <input type="text" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Epost" value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
                  <small id="emailHelp" className="form-text text-muted">Eposten til brukeren. Små bokstaver og ingen mellomrom</small>
                </div>
                <div className="input_wrapper">
                  <label htmlFor="role">Rolle:</label>
                  <select className="form-control" selected={this.state.role} onChange={e => this.setState({role: e.target.value}, () => console.log('role', this.state.role))}>
                    {roles && roles.map(role => (
                      <option value={role.id}>{role.name}</option>
                    ))}
                  </select>
                  <small id="roleHelp" className="form-text text-muted">Rollen brukeren skal ha i denne avdelingen</small>
                </div>
                <button type="submit">Legg til bruker</button>
              </form>
            </div>
          </div>


          <div className="tm-section-card">
            <h1 className="tm-section-card__header">Registrerte brukere</h1>
            <div className="tm-section-card__data">
            {users && <UsersList dispatch={dispatch} users={users} roles={roles} deleteUser={this.deleteUser} resetPassword={this.resetPassword} updateDepartmentsUsersRole={this.updateDepartmentsUsersRole.bind(this)} />}
            </div>
          </div>

        </div>
      </main>


    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const OfficesDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfficesDetailsComponent);

export default OfficesDetails