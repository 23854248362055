import React from 'react'

const Pagination = ({paginate, page, perPage, count, rows}) => (
  <nav className="tm-pagination">
    <ul className="tm-pagination__list">

      { /* <span className="tm-pagination__desc" style={{marginRight: '10px'}}>Viser side: {page} av {Math.ceil(count / perPage)}, og {rows.length} av {count} resultater</span>
      */ }

      {page === 1  ?
        <li className="tm-pagination__page tm-pagination__page--prev is-disabled"><span>Forrige</span></li> :
        <li className="tm-pagination__page tm-pagination__page--prev"><a href="" tabIndex="-1" onClick={e => paginate(e, 'previous')}>Forrige</a></li>
      }

      {/* If first, then show a disabled first */ }
      {page === 1 && <li className="tm-pagination__page tm-pagination__page--number"><a className="disabled is-beeing-displayed" href="">1</a></li>}

      {/* If last, then show two lower */}
      {(page * perPage > count && page !== 1) && <li className="tm-pagination__page tm-pagination__page--number"><a href="" onClick={e => paginate(e, page - 2)}>{page - 2}</a></li>}

      {/* if greater than one, then show one "lower" */}
      {page > 1 && <li className="tm-pagination__page tm-pagination__page--number"><a href="" onClick={e => paginate(e, page - 1)}>{page - 1}</a></li>}

      {/* if not first or last, then show current page in the middle */}
      {(page !== 1 && page * perPage < count) && <li className="tm-pagination__page tm-pagination__page--number"><a className="disabled is-beeing-displayed" href="">{page}</a></li>}

      {/* If not last, show one higher */}
      {page * perPage < count && <li className="tm-pagination__page tm-pagination__page--number"><a href="" onClick={e => paginate(e, page + 1)}>{page + 1}</a></li>}

      {/* If first, then show two higher */}
      {(page === 1 && page * perPage < count) && <li className="tm-pagination__page tm-pagination__page--number"><a href="" onClick={e => paginate(e, page + 2)}>{page + 2}</a></li>}

      {/* if last and not equal one, show a disabled last */}
      {(page * perPage > count && page !== 1) && <li className="tm-pagination__page tm-pagination__page--number"><a className="disabled is-beeing-displayed" href="">{page}</a></li>}

      {page * perPage > count ?
        <li className="tm-pagination__page tm-pagination__page--next is-disabled"><span>Neste</span></li> :
        <li className="tm-pagination__page tm-pagination__page--next"><a href="" tabIndex="-1" onClick={e => paginate(e, 'next')}>Neste</a></li>
      }

    </ul>
  </nav>
)

export default Pagination
