export const setModal = data => {
  return {
    type: 'SET_MODAL',
    data: data
  };
}

export const unsetModal = () => {
  return {
    type: 'UNSET_MODAL',
  };
}