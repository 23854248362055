import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import debounce from 'lodash/debounce'
import cookies from 'js-cookie'
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import config from '../../config'
import moment from 'moment'

import Pagination from '../pagination'

class UsersListComponent extends Component {
  constructor(props){
    super(props)

    this.state = {
      users: [],
      count: 0,
      page: 1,
      perPage: 50,

      loading: true,

      orderBy: {
        column: null,
        order: null,
      },

      selectedFilters: {
        searchString: '',
      },
    }
  }

  getExistingFiltersFromCookie = (cb) => {
    const selectedFilters = cookies.getJSON('TMFiltersUsers')
    if (selectedFilters) {
      this.setState({selectedFilters: selectedFilters}, () => cb())
    } else {
      cb()
    }
  };

  setFilterCookie = (cb) => {
    // console.log('this.state.selectedFilters', this.state.selectedFilters)
    cookies.set('TMFiltersUsers', this.state.selectedFilters, {expires: 0.2})
    cb()
  };

  componentDidMount() {
    this.getExistingFiltersFromCookie(() => {
      // this.getResource('subscriptions')
      // this.getResource('departments')
      this.getUsers((sales) => this.setState({filteredSales: sales}))
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const _this = this
    if (prevState !== this.state && prevState.loaded) {
      if (this.state.forceUpdate !== prevState.forceUpdate) {
        _this.getUsers()
      }
    }
  }

  getResource = (key) => {
    axios.get(`${config.apiUrl}/${key}`)
      .then(r => r.data.count ? this.setState({[key]: r.data.rows}) : console.log('no ', key))
      .catch(e => console.log('Cloud not fetch ', key, ' ',  e))
  };

  getUsers = debounce((cb) => {
    // const _this = this;
    this.setState({loading: true})

    let queryString = '?'

    // Get ordering
    if (this.state.orderBy.column && this.state.orderBy.order) {
      queryString += `&order=${this.state.orderBy.column}&order=${this.state.orderBy.order}`
    }

    // get serachstring
    if (this.state.selectedFilters.searchString) {
      queryString += `&searchString=${this.state.selectedFilters.searchString}`
      queryString += `&searchKeys=firstName`
      queryString += `&searchKeys=lastName`
      queryString += `&searchKeys=email`
      queryString += `&searchKeys=phone`
      queryString += `&searchKeys=username`
      queryString += `&searchKeys=personalNumber`
    }

    // handle page
    if (this.state.page) {
      queryString += `&page=${this.state.page}`
    }

    if (this.state.perPage) {
      queryString += `&perPage=${this.state.perPage}`
    }

    // Get all sales
    axios.get(`${config.apiUrl}/users${queryString}`)
      .then(r => {
        if (r.data) {
          this.setState({users: r.data.rows, count: r.data.count, loading: false})
        }
      }).catch(e => {
        console.log('error fetching sales', e)
      })
  }, 150);

  updateSelectedFilters = (filterKey, selectedFilters) => {
    const currentSelectedFilters = {...this.state.selectedFilters}
    currentSelectedFilters[filterKey] = selectedFilters
    this.setState({selectedFilters: currentSelectedFilters}, () => this.setFilterCookie(() => this.getUsers()))
  };

  toggleOrderBy = (key) => {
    // 1. If column is not set OR not right key, then set it to key AND 'ASC'
    if (!this.state.orderBy.column || this.state.orderBy.column !== key) {
      const orderBy = {...this.state.orderBy}
      orderBy.column = key
      orderBy.order = 'asc'
      this.setState({orderBy: orderBy}, () => this.getUsers())
    }
    // 2. Else if column is key but asc is set, then set desc
    else if (this.state.orderBy.column === key && this.state.orderBy.order === 'asc') {
      const orderBy = {...this.state.orderBy}
      orderBy.order = 'desc'
      this.setState({orderBy: orderBy}, () => this.getUsers())
    }
    // 3. REMOVE ORDER
    else {
      this.setState({orderBy: {column: null, order: null}}, () => this.getUsers())
    }
  };

  getColumnClass = (key) => {
    if (this.state.orderBy.column === key) {
      if (this.state.orderBy.order === 'asc') {
        return 'is-order-asc'
      } else {
        return 'is-order-desc'
      }
    }
    return ''
  };

  paginate = (e, key) => {
    e.preventDefault()
    let page = this.state.page
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page: page}, () => this.getUsers())
  };

  render () {
    const {users, count} = this.state

    return (
       <main>
        <div className="main-header">
          <div className="tm-filters">
            <div className="filter-group">
              <label htmlFor="search_string">Søk (Fornavn/Etternavn/Epost/Telefon/Brukernavn/Person.nr.)</label>
              <input type="text" id="search_string" className="form-control" value={this.state.selectedFilters.searchString} onChange={e => this.updateSelectedFilters('searchString', e.target.value)} />
            </div>
          </div>
        </div>


          <div className="main-body --wide-layout-temp-class">
            <div className="main-body__header">
              <h1>Viser {users.length} av {count} personer</h1>
              <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.users} />
            </div>
            <div className={`tm-table h-has-loader ${this.state.loading ? 'is-loading' : 'is-done-loading'}`}>
              <div className="lds_w"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
              <div className="tm-table__header-row">
                <div className={`tm-table__cell -sortable ${this.getColumnClass('firstName')}`} onClick={() => this.toggleOrderBy('firstName')}><span className="cell-content">Fornavn</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('lastName')}`} onClick={() => this.toggleOrderBy('lastName')}><span className="cell-content">Etternavn</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('email')}`} onClick={() => this.toggleOrderBy('email')}><span className="cell-content">Epost</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('phone')}`} onClick={() => this.toggleOrderBy('phone')}><span className="cell-content">Telefon</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('username')}`} onClick={() => this.toggleOrderBy('username')}><span className="cell-content">Brukernavn</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('personalNumber')}`} onClick={() => this.toggleOrderBy('personalNumber')}><span className="cell-content">Personnummer</span></div>
                <div className={`tm-table__cell -sortable ${this.getColumnClass('created')}`} onClick={() => this.toggleOrderBy('created')}><span className="cell-content">Opprettet</span></div>
              </div>
              <div className="tm-table__body">
                {users.map((s) => {
                  return (
                    <a className={`tm-table__row ${(s.botSuccess === false && s.reported === false) && 'tm-table__row--emphasized'}`}
                        key={s.id}
                        href={`/#/users/${s.id}`}>
                      <div className="tm-table__cell"><div className="cell-content">{s.firstName && s.firstName} {!s.firstName && 'Ukjent'}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.lastName && s.lastName} {!s.lastName && 'Ukjent'}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.email && s.email} {!s.email && 'Ukjent'}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.phone && s.phone} {!s.phone && 'Ukjent'}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.username && s.username} {!s.username && 'Ukjent'}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.personalNumber && s.personalNumber} {!s.personalNumber && 'Ukjent'}</div></div>
                      <div className="tm-table__cell"><div className="cell-content">{s.created && moment(s.created).format('DD/MM/YYYY')}</div></div>
                    </a>
                  )
                })}
                {users.length === 0 &&
                  <div>Ingen salg med dette søket</div>
                }
              </div>
            </div>
            <div className="main-body__footer">
              <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.users} />
            </div>
          </div>


        </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const UsersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersListComponent);

export default UsersList
