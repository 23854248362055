import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import config from '../../config'
import _ from 'lodash'

import Pagination from '../pagination'

class CampaignsDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      campaign: {
        name: '',
        botKey: '',
        order: '0',
        active: 'false',
        departmentId: null,
      },

      departments: [],
      subDepartments: [],
      subscriptions: [],
      selectedSubscriptions: [],
      count: 0,
      page: 1,
      perPage: 10,
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/campaigns/${this.props.layout.data.campaignId}`)
      .then(res => {
        if (res.data) {
          this.setState({campaign: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })

    axios.get(`${config.apiUrl}/departments?departmentId=`)
      .then(r => {
        if (r.data.rows)
          this.setState({departments: r.data.rows}, () => this.getSubDepartments())
      })
      .catch(e => console.error(e))

    this.getSubscriptions()
  }

  getSubDepartments = () => {
    const {departments, subDepartments} = this.state
    departments.forEach(dep => {
      axios.get(`${config.apiUrl}/departments?departmentId=${dep.id}`)
        .then(r => {
          if (r.data.rows) {
            const copy = [...subDepartments]
            r.data.rows.forEach(row => copy.push(row))
            this.setState({subDepartments: copy})
          }
        })
        .catch(e => console.error(e))
    })
  };

  getSubscriptions = () => {
    axios.get(`${config.apiUrl}/subscriptions?perPage=500`)
      .then(res => {
        if (res.data.rows) {
          this.setState({subscriptions: res.data.rows, count: res.data.count}, () => this.getSelectedSubscriptions())
        }
      })
      .catch(e => {
        console.error(e)
      })
  };

  getSelectedSubscriptions = () => {
    axios.get(`${config.apiUrl}/campaigns/${this.props.layout.data.campaignId}/subscriptions`)
      .then(r => {
        if (r.data) {
          this.setState({selectedsubscriptions: r.data})
        }
      }).catch(e => {
        console.log(e)
      })
  };

  deleteCampaign = () => {
    axios.delete(`${config.apiUrl}/campaigns/${this.props.layout.data.campaignId}`)
      .then(res =>{
        window.location = `#/campaigns`
        toastr.success('Slettet kampanje')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke slette kampanje', e.response.data.message)
          console.error(e.response.data.message)
        }
      })
  };

  updateCampaign = (e) => {
    e.preventDefault()
    const {campaign} = this.state
    axios.put(`${config.apiUrl}/campaigns/${this.props.layout.data.campaignId}`, {
      name: campaign.name,
      botKey: campaign.botKey,
      order: parseInt(campaign.order),
      active: campaign.active,
      departmentId: campaign.departmentId === 'null' ? null : campaign.departmentId
    })
      .then(res => {
        toastr.success('tilleggstjeneste oppdatert')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere kampanje', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere kampanje')
        }
      })
  };


  paginate = (e, key) => {
    e.preventDefault()
    let page = this.state.page
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page: page}, () => this.getSubscriptions())
  };

  handleCheck = (e, id) => {
    if (_.has(this.props.layout, 'data.campaignId')) {
      if (e.target.checked) {
        axios.post(`${config.apiUrl}/campaigns/${this.props.layout.data.campaignId}/subscriptions`, {id: id})
          .then(res => {
            this.getSubscriptions()
          })
          .catch(e => {
            console.error(e.response)
          })
      } else {
        axios.delete(`${config.apiUrl}/campaigns/${this.props.layout.data.campaignId}/subscriptions`, {data: {id: id}})
          .then(res => {
            this.getSubscriptions()
          })
          .catch(e => {
            console.error(e.response)
          })
      }
    }
  };

  render () {
    const { campaign, subscriptions, selectedsubscriptions, departments, subDepartments } = this.state

    return !campaign ? <div>Loading...</div> : (
      <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="main-body__header">
            <h1>Viser {campaign.name}</h1>
            <a href="#/campaigns">Tilbake</a>
          </div>

          <div className="tm-section-card" style={{marginLeft: '2rem', marginRight: '2rem'}}>
            <h1 className="tm-section__header">Endre kampanje</h1>
            <div className="tm-section-card__data add-user">
              <form onSubmit={e => this.updateCampaign(e)} id="update_extraService" className="add-user__form">

                <div className="input_wrapper">
                  <label htmlFor="name">Navn:</label>
                  <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Navn" value={campaign.name} onChange={e => {
                    const copy = Object.assign({}, campaign)
                    copy.name = e.target.value
                    this.setState({campaign: copy})
                  }} />
                  <small id="emailHelp" className="form-text text-muted">Navnet vil være synlig i salgskanaler</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="botKey">Bot-nøkkel:</label>
                  <input type="text" className="form-control" id="botKey" aria-describedby="botKeyHelp" placeholder="Bot-nøkkel" value={campaign.botKey} onChange={e => {
                    const copy = Object.assign({}, campaign)
                    copy.botKey = e.target.value
                    this.setState({campaign: copy})
                  }} />
                  <small id="botKeyHelp" className="form-text text-muted">Nøkkelen som brukes når salg mates inn via BOT</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="order">Rekkefølge:</label>
                  <input type="text" min={0} className="form-control" id="order" aria-describedby="orderHelp" placeholder="0" value={campaign.order || ''} onChange={e => {
                    const copy = Object.assign({}, campaign)
                    copy.order = e.target.value
                    this.setState({campaign: copy})
                  }} />
                  <small id="orderHelp" className="form-text text-muted">Sorteringsnøkkel for visning i salgskanaler</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="active">Aktiv:</label>
                  <select className="form-control" value={campaign.active} onChange={e => {
                    const copy = Object.assign({}, campaign)
                    copy.active = e.target.value
                    this.setState({campaign: copy})
                  }}>
                    <option value={true}>Aktiv</option>
                    <option value={false}>Ikke aktiv</option>
                  </select>
                  <small id="active" className="form-text text-muted">Om kampanjen er aktivt, og skal vises i salgskanaler</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="departmentId">Låst til en avdeling:</label>
                  <select className="form-control" value={campaign.departmentId} onChange={e => {
                    const copy = Object.assign({}, campaign)
                    copy.departmentId = e.target.value
                    this.setState({campaign: copy})
                  }}>
                    <option value="null">Ingen</option>
                    {departments.map(dep => (
                      <React.Fragment>
                        <option value={dep.id} key={dep.id}>{dep.name}</option>
                        {subDepartments.filter(d => d.departmentId === dep.id).map(d => <option value={d.id} key={d.id}>--{d.name}</option>)}
                      </React.Fragment>
                    ))}
                  </select>
                  <small id="departmentId" className="form-text text-muted">Om kampanjen er aktivt, og skal vises i salgskanaler</small>
                </div>

                <button type="submit">Oppdater</button>
              </form>
              {/*<button class="btn btn-danger small-button" onClick={e => this.deleteCampaign(e)}>Slett</button>*/}
            </div>

            <h1 className="tm-section__header" style={{marginTop: '3rem'}}>Kampanjen tilhører disse abonnementene</h1>
            <div className="tm-table__header-row">
              <div className={`tm-table__cell`}><span className="cell-content">Aktiv</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Navn</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Bot-nøkkel</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Aktiv</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Trygg</span></div>
            </div>
            <div className="tm-section-card__data add-user">
              {subscriptions && subscriptions.map((subscription) => {
                return (
                  <div className="tm-table__row" key={subscription.id}>
                    <div className="tm-table__cell">
                      <div className="input_wrapper">
                        <input type="checkbox"
                          id={subscription.id}
                          checked={_.find(selectedsubscriptions, selectedsubscriptions => selectedsubscriptions.id === subscription.id)}
                          value={subscription.id}
                          onChange={e => this.handleCheck(e, subscription.id)} />
                        <label htmlFor={subscription.id}></label>
                      </div>
                    </div>
                    <div className="tm-table__cell">{subscription.name || 'Ukjent'}</div>
                    <div className="tm-table__cell">{subscription.botKey || 'Ukjent'}</div>
                    <div className="tm-table__cell">{subscription.kidSafe ? 'Trygg' : 'Ikke trygg'}</div>
                    <div className="tm-table__cell">{subscription.active ? 'Aktiv' : 'Ikke aktiv'}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignsDetails)
