import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'

import config from '../../../config'

import { unsetModal } from '../../../actions/modal'

class AddSubscription extends Component {
  constructor(props){
    super(props)
    this.state = {
      name: '',
      botKey: '',
      amount: '0',
      order: '0',
      active: true,
      kidSafe: false
    }
  }

  addSubscription = e => {
    e.preventDefault()
    console.log('clicked')

    const data = Object.assign({}, this.state)
    data.amount = parseInt(data.amount)
    data.order = parseInt(data.order)

    axios.post(`${config.apiUrl}/subscriptions`, data)
      .then(res => {
        toastr.success('Abonnement lagt til')
        window.location.reload()
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere selger', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere selger')
        }
      })
  };

  render () {
    const {name, botKey, amount, order, active, kidSafe} = this.state

    return (
      <form onSubmit={e => this.addSubscription(e)}>
        <h1>Ny prisplan</h1>

        <div className="input_wrapper">
          <label htmlFor="name">Navn:</label>
          <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Navn" value={name} onChange={e => this.setState({name: e.target.value})} />
          <small id="emailHelp" className="form-text text-muted">Navnet til prisplanen. Dette vil være synlig i salgskanaler.</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="botKey">Bot-nøkkel:</label>
          <input type="text" className="form-control" id="botKey" aria-describedby="botKeyHelp" placeholder="Bot-nøkkel" value={botKey} onChange={e => this.setState({botKey: e.target.value})} />
          <small id="botKeyHelp" className="form-text text-muted">Nøkkelen som brukes når salg mates inn via BOT</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="amount">Kroner/mnd:</label>
          <input type="text" min={0} className="form-control" id="amount" aria-describedby="amountHelp" placeholder="0" value={amount|| ''} onChange={e => this.setState({amount: e.target.value})} />
        </div>

        <div className="input_wrapper">
          <label htmlFor="order">Rekkefølge:</label>
          <input type="text" min={0} className="form-control" id="order" aria-describedby="orderHelp" placeholder="0" value={order || ''} onChange={e => this.setState({order: e.target.value})} />
          <small id="orderHelp" className="form-text text-muted">Sorteringsnøkkel for visning i salgskanaler</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="active">Aktiv:</label>
          <div className="select_wrapper">
          <select className="form-control" selected={active} onChange={e => this.setState({active: e.target.value})}>
            <option value={true}>Aktiv</option>
            <option value={false}>Ikke aktiv</option>
          </select>
          </div>
          <small id="active" className="form-text text-muted">Om prisplanen er <strong>aktiv</strong> vil det vises i salgskanaler.</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="kidSafe">Trygg:</label>
          <div className="select_wrapper">
            <select className="form-control" selected={kidSafe} onChange={e => this.setState({kidSafe: e.target.value})}>
              <option value={true}>Ikke Trygg</option>
              <option value={false}>Trygg</option>
            </select>
          </div>
        </div>

        <button className="large">Legg til</button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubscription)
