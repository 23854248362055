import {
  setSalesList,
  setSalesDetail,
  setUkraineSalesList,
  setUkraineSaleDetails,
  setActivity,
  setActivityDetails,
  setSettingsList,
  setDepartmentsList,
  setDepartmentsDetails,
  setUsersList,
  setRolesList,
  setRolesDetails,
  setStatisticsList,
  setSubscriptionsList,
  setSubscriptionsDetails,
  setExtraServicesList,
  setExtraServicesDetails,
  setCampaignsList,
  setCampaignsDetails,
} from './actions/layout'

export const setRoute = ({ store }) => {
  const path = window.location.hash.split('/')
  const rxuuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  // set property detail if url matches
  if (path[1] === 'sales') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setSalesDetail(path[2]))
    } else {
      store.dispatch(setSalesList())
    }
  } else if (path[1] === 'ukraine') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setUkraineSaleDetails(path[2]))
    } else {
      store.dispatch(setUkraineSalesList())
    }
  } else if (path[1] === 'activity') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setActivityDetails(path[2]))
    } else {
      store.dispatch(setActivity())
    }
  } else if (path[1] === 'settings') {
    store.dispatch(setSettingsList())
  } else if (path[1] === 'departments') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setDepartmentsDetails(path[2]))
    } else {
      store.dispatch(setDepartmentsList())
    }
  } else if (path[1] === 'users') {
    if (path[2] && path[2].match(rxuuid)) {
      // store.dispatch(setDepartmentsDetails(path[2]))
    } else {
      store.dispatch(setUsersList())
    }
  } else if (path[1] === 'roles') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setRolesDetails(path[2]))
    } else {
      store.dispatch(setRolesList())
    }
  } else if (path[1] === 'statistics') {
    if (path[2] && path[2].match(rxuuid)) {
      // store.dispatch(setRolesDetails(path[2]))
    } else {
      store.dispatch(setStatisticsList())
    }
  } else if (path[1] === 'subscriptions') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setSubscriptionsDetails(path[2]))
    } else {
      store.dispatch(setSubscriptionsList())
    }
  } else if (path[1] === 'extra-services') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setExtraServicesDetails(path[2]))
    } else {
      store.dispatch(setExtraServicesList())
    }
  } else if (path[1] === 'campaigns') {
    if (path[2] && path[2].match(rxuuid)) {
      store.dispatch(setCampaignsDetails(path[2]))
    } else {
      store.dispatch(setCampaignsList())
    }
  } else {
    store.dispatch(setSalesList())
  }
  //  store.dispatch(setSalesList())
}
