import React, { Component } from 'react';
import { connect } from 'react-redux'

import Modals from '../components/modals'
import Header from '../components/header'
import SalesList from '../components/sales-list'
import UkraineSalesList from '../components/ukraine-sales-list'
import UkraineSaleDetails from '../components/ukraine-sale-details'
import Activity from '../components/activity'
import ActivityDetails from '../components/activity-details'
import SaleDetails from '../components/sale-details'
import Settings from '../components/settings'
import Departments from '../components/departments-list'
import DepartmentsDetails from '../components/departments-details'
import UsersList from '../components/users-list'
import RolesList from '../components/roles-list'
import RolesDetails from '../components/roles-details'
import StatisticsList from '../components/statistics-list'
import SubscriptionsList from '../components/subscriptions-list'
import SubscriptionsDetails from '../components/subscriptions-details'
import ExtraServicesList from '../components/extra-services-list'
import ExtraServicesDetails from '../components/extra-services-details'
import CampaignsList from '../components/campaigns-list'
import CampaignsDetails from '../components/campaigns-details'
import StandsList from '../components/stands-list'
import '../styles/main.scss'

class DashboardComponent extends Component {
  componentDidMount() {
    document.cookie = 'tminternal=internal;path=/;domain=talkmore.no;max-age=31536000'
  }

  renderLayout = () => {
    switch (this.props.layout.route) {
      case 'SALES_LIST':
        return <SalesList />
      case 'SALE_DETAILS':
        return <SaleDetails />
      case 'UKRAINE_SALES_LIST':
        return <UkraineSalesList />
      case 'UKRAINE_SALE_DETAILS':
        return <UkraineSaleDetails />
      case 'ACTIVITY':
        return <Activity />
      case 'ACTIVITY_DETAILS':
        return <ActivityDetails />
      case 'SETTINGS_LIST':
        return <Settings />
      case 'DEPARTMENTS_LIST':
        return <Departments />
      case 'DEPARTMENTS_DETAILS':
        return <DepartmentsDetails />
      case 'USERS_LIST':
        return <UsersList />
      case 'ROLES_LIST':
        return <RolesList />
      case 'ROLES_DETAILS':
        return <RolesDetails />
      case 'STATISTICS_LIST':
        return <StatisticsList />
      case 'SUBSCRIPTIONS_LIST':
        return <SubscriptionsList />
      case 'SUBSCRIPTIONS_DETAILS':
        return <SubscriptionsDetails />
      case 'EXTRA_SERVICES_LIST':
        return <ExtraServicesList />
      case 'EXTRA_SERVICES_DETAILS':
        return <ExtraServicesDetails />
      case 'CAMPAIGNS_LIST':
        return <CampaignsList />
      case 'CAMPAIGNS_DETAILS':
        return <CampaignsDetails />
      case 'STANDS_LIST':
        return <StandsList />
      default:
        return <SalesList />
    }
  };

  render() {
    const layout = this.renderLayout()
    return (
      <div className="dashboard">
        <Modals />
        <Header />
        <div className="site-body">
          {layout}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout
  }
}

const Dashboard = connect(
  mapStateToProps,
)(DashboardComponent);

export default Dashboard