import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'

class SettingsComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      settings: null
    }
  }

  componentDidMount() {
    const _this = this
    axios.get(`${config.apiUrl}/product/bc7dcf9d-b999-4696-8481-6681d5bd137f/settings`)
      .then(function (response) {
        if (response.data.data) {
          _this.setState({settings: response.data.data})
        }
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  updateSettings = (settings, cb) => {
    const _this = this
    axios.put(`${config.apiUrl}/product/bc7dcf9d-b999-4696-8481-6681d5bd137f/settings`, settings)
      .then(function (response) {
        if (response.data.data) {
          _this.setState({settings: response.data.data})
        }
      })
      .catch(function (error) {
        console.error(error)
      })
  };

  removeRecipient = (rci) => {
    const currentSettings = Object.assign({}, this.state.settings)
    const excelRecipients = currentSettings.globalSettings.excelRecipients
    excelRecipients.splice(excelRecipients.indexOf(rci), 1)
    currentSettings.globalSettings.excelRecipients = excelRecipients
    this.updateSettings(currentSettings)
  };

  addRecipient = (e) => {
    e.preventDefault()
    const currentSettings = Object.assign({}, this.state.settings)
    currentSettings.globalSettings.excelRecipients.push(e.target.email.value)
    this.updateSettings(currentSettings)
    e.target.email.value = ''
  };

  render () {
    const { settings } = this.state
    let content = <div>Loading...</div>

    if (settings && settings.id) {
      content = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <h1>Innstillinger</h1>
              <hr />

              <h2>Mottakere av excel fil:</h2>

              <h4>Mottakere:</h4>
              <ul>
                {settings.globalSettings && settings.globalSettings.excelRecipients && settings.globalSettings.excelRecipients.map(rci => (
                  <li key={`rci-${rci}`}>{rci} - <span onClick={e => this.removeRecipient(rci)}><i className="fa fa-fw fa-times"></i></span></li>
                ))}
              </ul>

              <h4>Legg til ny mottaker:</h4>
              <form onSubmit={e => this.addRecipient(e)}>
                <div className="form-group">
                  <label htmlFor="email">Epost:</label>
                  <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Legg til epost" />
                  <small id="emailHelp" className="form-text text-muted">Hvem skal motta listen med salg?</small>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      )
    }
    return content
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsComponent);

export default Settings