export const setSalesList = () => {
  window.location.hash = `/sales`
  return {
    type: 'SET_LAYOUT',
    route: 'SALES_LIST',
    tab: null,
    data: {},
  };
}

export const setSalesDetail = (id) => {
  console.log('id', id)
  window.location.hash = `/sales/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'SALE_DETAILS',
    tab: null,
    data: {
      saleId: id
    },
  };
}

export const setUkraineSalesList = () => {
  window.location.hash = `/ukraine`
  return {
    type: 'SET_LAYOUT',
    route: 'UKRAINE_SALES_LIST',
    tab: null,
    data: {},
  };
}

export const setUkraineSaleDetails = (id) => {
  window.location.hash = `/ukraine/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'UKRAINE_SALE_DETAILS',
    tab: null,
    data: {
      ukraineSignupId: id
    },
  };
}

export const setActivity = () => {
  window.location.hash = `/activity`
  return {
    type: 'SET_LAYOUT',
    route: 'ACTIVITY',
    tab: null,
    data: {},
  };
}

export const setActivityDetails = (id) => {
  console.log('id', id)
  window.location.hash = `/activity/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'ACTIVITY_DETAILS',
    tab: null,
    data: {
      submissionId: id
    },
  };
}

export const setSettingsList = () => {
  window.location.hash = `/settings`
  return {
    type: 'SET_LAYOUT',
    route: 'SETTINGS_LIST',
    tab: null,
    data: {},
  };
}

export const setDepartmentsList = () => {
  window.location.hash = `/departments`
  return {
    type: 'SET_LAYOUT',
    route: 'DEPARTMENTS_LIST',
    tab: null,
    data: {},
  };
}

export const setDepartmentsDetails = (id) => {
  console.log('id', id)
  window.location.hash = `/departments/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'DEPARTMENTS_DETAILS',
    tab: null,
    data: {
      departmentId: id
    },
  };
}

export const setUsersList = () => {
  window.location.hash = `/users`
  return {
    type: 'SET_LAYOUT',
    route: 'USERS_LIST',
    tab: null,
    data: {},
  };
}

export const setRolesList = () => {
  window.location.hash = `/roles`
  return {
    type: 'SET_LAYOUT',
    route: 'ROLES_LIST',
    tab: null,
    data: {},
  };
}

export const setRolesDetails = (id) => {
  console.log('id', id)
  window.location.hash = `/roles/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'ROLES_DETAILS',
    tab: null,
    data: {
      roleId: id
    },
  };
}

export const setStatisticsList = () => {
  window.location.hash = `/statistics`
  return {
    type: 'SET_LAYOUT',
    route: 'STATISTICS_LIST',
    tab: null,
    data: {},
  };
}

export const setSubscriptionsList = () => {
  window.location.hash = `/subscriptions`
  return {
    type: 'SET_LAYOUT',
    route: 'SUBSCRIPTIONS_LIST',
    tab: null,
    data: {},
  };
}

export const setSubscriptionsDetails = (id) => {
  window.location.hash = `/subscriptions/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'SUBSCRIPTIONS_DETAILS',
    tab: null,
    data: {
      subscriptionId: id
    },
  };
}

export const setExtraServicesList = () => {
  window.location.hash = `/extra-services`
  return {
    type: 'SET_LAYOUT',
    route: 'EXTRA_SERVICES_LIST',
    tab: null,
    data: {},
  };
}

export const setExtraServicesDetails = (id) => {
  window.location.hash = `/extra-services/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'EXTRA_SERVICES_DETAILS',
    tab: null,
    data: {
      extraServiceId: id
    },
  };
}

export const setCampaignsList = () => {
  window.location.hash = `/campaigns`
  return {
    type: 'SET_LAYOUT',
    route: 'CAMPAIGNS_LIST',
    tab: null,
    data: {},
  };
}

export const setCampaignsDetails = (id) => {
  window.location.hash = `/campaigns/${id}`
  return {
    type: 'SET_LAYOUT',
    route: 'CAMPAIGNS_DETAILS',
    tab: null,
    data: {
      campaignId: id
    },
  };
}

export const setStandsList = () => {
  window.location.hash = `/stands`
  return {
    type: 'SET_LAYOUT',
    route: 'STANDS_LIST',
    tab: null,
    data: {},
  };
}

