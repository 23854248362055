import axios from 'axios'
import config from './config'

import { setSignedIn, setSignedOut } from './actions/authenticate'
import { setUser, unsetUser } from './actions/user'
import { setLoaded } from './actions/loading'

const handleSignedIn = (dispatch, response) => {
  dispatch(setUser({user: response.data.user, departments: response.data.departments, roles: response.data.roles}))
  dispatch(setSignedIn())
}

const handleSignedOut = (dispatch) => {
  dispatch(setSignedOut())
  dispatch(unsetUser())
}

export const checkIfLoggedIn = ({store, dispatch}) => {
  const dp = dispatch || store.dispatch;
  const myStorage = window.localStorage
  if (myStorage.getItem('token')) {
    axios.defaults.headers.common['authorization'] = myStorage.getItem('token')
    axios.get(`${config.apiUrl}/authenticate`)
      .then(response => {
        console.log('response', response)
        response.data.success ? handleSignedIn(dp, response) : handleSignedOut(dp)
        dp(setLoaded())
      })
      .catch(error => {
        handleSignedOut(dp)
        logOut(dp)
        dp(setLoaded())
      })
  } else {
    handleSignedOut(dp)
    dp(setLoaded())
  }
}

export const logOut = (dispatch) => {
  const myStorage = window.localStorage

  delete axios.defaults.headers.common['authorization']
  myStorage.removeItem('token')
  dispatch(unsetUser())
  dispatch(setSignedOut())
}