import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import debounce from 'lodash/debounce'
import cookies from 'js-cookie'
import sortBy from 'lodash.sortby'

import config from '../../config'
import moment from 'moment'

import Pagination from '../pagination'

import { setModal } from '../../actions/modal'

function prettifyGroupBy(str) {
  const months = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  if (/^\d+-\d+$/.test(str)) {
    const parts = str.split('-')
    return `${months[parseInt(parts[0]) - 1]} ${parts[1]}`
  } else {
    return str
  }
}

class StandsListComponent extends Component {
  constructor(props){
    super(props)

    this.state = {
      stands: [],
      departments: [],
      subDepartments: [],
      count: 0,
      page: 1,
      perPage: 31,
      departmentId: '',
      groupBy: 'date',

      loading: true,

      orderBy: {
        column: 'order',
        order: 'ASC',
      },

      selectedFilters: {
        searchString: '',
      },
    }
  }

  componentDidMount() {
    this.getStands(stands => this.setState({filteredStands: stands}))
    axios.get(`${config.apiUrl}/departments?departmentId=`)
      .then(r => {
        if (r.data.rows) {
          this.setState({departments: r.data.rows}, this.getSubDepartments)
        }
      })
      .catch(e => console.error(e))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state && prevState.loaded) {
      if (this.state.forceUpdate !== prevState.forceUpdate) {
        this.getStands()
      }
    }
  }

  getSubDepartments = () => {
    const {departments, subDepartments} = this.state
    departments.forEach(dep => {
      axios.get(`${config.apiUrl}/departments?departmentId=${dep.id}`)
        .then(r => {
          if (r.data.rows) {
            const copy = [...subDepartments]
            r.data.rows.forEach(row => copy.push(row))
            this.setState({subDepartments: copy})
          }
        })
        .catch(e => console.error(e))
    })
  };

  getStands = debounce((cb) => {
    // const _this = this;
    this.setState({loading: true})

    const keys = ['page', 'perPage', 'departmentId', 'groupBy']
    const params = {}
    keys.forEach(k => params[k] = this.state[k])

    const queryString = 
      Object.keys(params)
        .filter(key => params[key])
        .map(key => [key, params[key]].join('='))
        .join('&')

    // Get all stands
    axios.get(`${config.apiUrl}/stands?${queryString}`)
      .then(r => {
        if (r.data) {
          this.setState({stands: r.data.rows, count: r.data.count, loading: false}, () => console.log('stands:', this.state.stands))
        }
      }).catch(e => {
        console.log('error fetching stands', e)
      })
  }, 150);

  paginate = (e, key) => {
    e.preventDefault()
    let { page } = this.state
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page}, this.getStands)
  };

  render () {
    const { stands, count, departments, subDepartments } = this.state

    const radioStyles = {
      opacity: 1,
      position: 'relative',
      verticalAlign: 'top',
    }
    console.log('RENDERED stands:', stands)
    return (
       <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="main-body__header">
            <h1>Stands</h1>
            <div>
              <span>Summér etter:&nbsp;</span>
              <input type="radio" checked={this.state.groupBy === 'date'} style={radioStyles} onChange={e => { this.setState({groupBy: 'date'}, this.getStands) }} /> Dato
              &nbsp;
              &nbsp;
              <input type="radio" checked={this.state.groupBy === 'month'} style={radioStyles} onChange={e => { this.setState({groupBy: 'month'}, this.getStands) }} /> Måned
            </div>
            <div>
              <span>Salgskontor:&nbsp;</span>
              <select className="form-control" value={this.state.departmentId} onChange={e => { this.setState({departmentId: e.target.value}, this.getStands) }}>
                <option value="">Alle</option>
                {departments.map(dep => (
                  <React.Fragment key={`top-dep-${dep.id}`}>
                    <option value={dep.id} key={dep.id}>{dep.name}</option>
                    {subDepartments.filter(d => d.departmentId === dep.id).map(d => <option value={d.id} key={d.id}>--{d.name}</option>)}
                  </React.Fragment>
                ))}
              </select>
            </div>
            <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.stands} />
          </div>
          <div className={`tm-table h-has-loader ${this.state.loading ? 'is-loading' : 'is-done-loading'}`}>
            <div className="lds_w"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            <div className="tm-table__header-row">
              <div className={`tm-table__cell`}><span className="cell-content">{this.state.groupBy === 'date' ? 'Dag' : 'Måned'}</span></div>
              <div className={`tm-table__cell`}><span className="cell-content">Stands</span></div>
            </div>
            <div className="tm-table__body">
              {sortBy(stands, [this.state.groupBy]).reverse().map((s, i) => {
                return (
                  <div key={`stand-row-${i}`} className={`tm-table__row`}>
                    <div className="tm-table__cell"><div className="cell-content">{prettifyGroupBy(s[this.state.groupBy])}</div></div>
                    <div className="tm-table__cell"><div className="cell-content">{s.count}</div></div>
                  </div>
                )
              })}
              {stands.length === 0 &&
                <div>Ingen stands med dette søket</div>
              }
            </div>
          </div>
          <div className="main-body__footer">
            <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.stands} />
          </div>
        </div>


      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandsListComponent)

