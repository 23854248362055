import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'

import config from '../../../config'

import { unsetModal } from '../../../actions/modal'

class AddExtraService extends Component {
  constructor(props){
    super(props)
    this.state = {
      name: '',
      botKey: '',
      amount: '0',
      order: '0',
      active: true,
    }
  }

  addSubscription = e => {
    e.preventDefault()
    console.log('clicked')

    const { dispatch } = this.props
    const data = Object.assign({}, this.state)
    data.amount = parseInt(data.amount)
    data.order = parseInt(data.order)

    axios.post(`${config.apiUrl}/extra-services`, data)
      .then(res => {
        toastr.success('tilleggstjeneste lagt til')
        window.location.hash = `#/extra-services/${res.data.id}`
        dispatch(unsetModal())
        // window.location.reload()
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke legge til tilleggstjeneste', e.response.data.message)
        } else {
          toastr.error('Kunne ikke legge til tilleggstjeneste')
        }
      })
  };

  render () {
    const {name, botKey, amount, order, active} = this.state

    return (
      <form onSubmit={e => this.addSubscription(e)}>
        <h1>Ny tilleggstjeneste</h1>

        <div className="input_wrapper">
          <label htmlFor="name">Navn:</label>
          <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Navn" value={name} onChange={e => this.setState({name: e.target.value})} />
          <small id="emailHelp" className="form-text text-muted">Navnet vil være synlig i salgskanaler</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="botKey">Bot-nøkkel:</label>
          <input type="text" className="form-control" id="botKey" aria-describedby="botKeyHelp" placeholder="Bot-nøkkel" value={botKey} onChange={e => this.setState({botKey: e.target.value})} />
          <small id="botKeyHelp" className="form-text text-muted">Nøkkelen som brukes når salg mates inn via BOT</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="amount">Kroner/mnd:</label>
          <input type="text" min={0} className="form-control" id="amount" aria-describedby="amountHelp" placeholder="0" value={amount|| ''} onChange={e => this.setState({amount: e.target.value})} />
          <small id="amountHelp" className="form-text text-muted">Kroner per måned for tilleggstjenesten</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="order">Rekkefølge:</label>
          <input type="text" min={0} className="form-control" id="order" aria-describedby="orderHelp" placeholder="0" value={order || ''} onChange={e => this.setState({order: e.target.value})} />
          <small id="orderHelp" className="form-text text-muted">Sorteringsnøkkel for visning i salgskanaler</small>
        </div>

        <div className="input_wrapper">
          <label htmlFor="active">Aktiv:</label>
          <div className="select_wrapper">
            <select className="form-control" selected={active} onChange={e => this.setState({active: e.target.value})}>
              <option value={true}>Aktiv</option>
              <option value={false}>Ikke aktiv</option>
            </select>
          </div>
          <small id="active" className="form-text text-muted">Om abonomentet er aktivt, og skal vises i salgskanaler</small>
        </div>

        <button className="large">Legg til</button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExtraService)
