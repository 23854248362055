import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'

class RenderDepartmentListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: null,
      childDepartments: []
    }
  }

  componentDidMount() {
    const { user } = this.props
    axios.get(`${config.apiUrl}/departments/${this.props.department.id}/users`)
      .then(res => {
        if (res.data) {
          this.setState({users: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })

    // change so we only get the departments available for this user
    if (user && user.departments) {
      axios.get(`${config.apiUrl}/departments/${this.props.department.id}/departments`)
        .then(res => {
          if (res.data) {

            let childDepartments
            // check if the user is admin if the "current main  department"
            if (user.roles.find(ur => ur.name === 'admin' && ur.departmentsUsers.departmentId === this.props.department.id)) {
              childDepartments = res.data
            } else {
              childDepartments = res.data.filter(dep => user.departments.some(ud => ud.id === dep.id))
            }

            this.setState({childDepartments: childDepartments})
          }
        })
        .catch(e => {
          console.error(e)
        })
    }
  }

  render() {
    const { department} = this.props
    const { users, childDepartments } = this.state

    return (
      <div key={`department-top-${department.id}`}>
        <a href={`/#/departments/${department.id}`} className="tm-table__row">
          <div className="tm-table__cell">{department.name ? department.name : 'Mangler navn'}</div>
          <div className="tm-table__cell">{users ? users.length : 'Laster inn'}</div>
        </a>
        {/* render children */ }
        {childDepartments && childDepartments.length > 0 && childDepartments.map((childDepartment) => {
          return <div style={{marginLeft: 20}}><RenderDepartmentListRow key={`department-${childDepartment.id}`} department={childDepartment} departments={childDepartments} /></div>
        })}
      </div>
    )
  }
}

class DepartmentsComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      count: null,
      departments: [],
      loading: true
    }
  }

  componentDidMount() {
    // get top level departments
    axios.get(`${config.apiUrl}/departments?order=name&departmentId=`)
      .then(res => {
        if (res.data.rows) {
          this.setState({departments: res.data.rows, loading: false})
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  render () {
    const { departments } = this.state
    const { user } = this.props

    return (
      <main>
        <div className="main-body">
          <div className="tm-section-card">
            <h1 className="tm-section-card__header">Teams</h1>
            <div className="tm-table tm-table--narrow tm-section-card__data">
              <div className="tm-table__header-row">
                <div className="tm-table__cell">Team</div>
                <div className="tm-table__cell">Antall brukere</div>
              </div>
              <div className="tm-table__body">
                {departments.map((department) => {
                  return <RenderDepartmentListRow key={`departmentTop${department.name}`} department={department} departments={departments} user={user} />
                })}
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => { 
  return {
    dispatch: dispatch,
  }
}

const DepartmentsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentsComponent);

export default DepartmentsList