import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'

import { logOut } from '../auth'

import Anon from '../components/anon'
import Dashboard from './dashboard'


const AppComponent = ({dispatch, authenticate, user, loading}) => {
  let content
  let css_classes_app
  if (!loading) {
    if (authenticate && user.user.id && _.find(user.roles, role => role.name === 'admin' || role.name === 'Avdelingsadministrator')) {
      content = <Dashboard />
      css_classes_app = 'app logged-in --fieldsales-admin lvl-tm'
    } else if (authenticate) {
      content = <div>You don't have the right permissions<br /><a href="" onClick={() => logOut(dispatch)}>Logg ut</a></div>
      css_classes_app = ''
    } else {
      content = <Anon />
      css_classes_app = 'app not-logged-in --fieldsales-admin lvl-tm'
    }
  }
  return (
    <div className={css_classes_app}>
      {content}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    authenticate: state.authenticate,
    loading: state.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent);

export default App
