module.exports = (function () {
  const local = {
    // apiUrl: 'http://localhost:4747/api/v1',
    apiUrl: 'http://srv1:9128/api/v1',
    newApiUrl: 'http://srv1:7651/api/v1',
    staticMapsKey: 'AIzaSyBqZy1VEqmMAd2L9DqB_lcBqmZzC8rNNp4',
  }

  const remote = {
    prod: {
      apiUrl: 'https://talkmore-api.levelupify.com/api/v1',
      // newApiUrl: 'https://api-talkmore.levelupify.com/api/v1',
      newApiUrl: 'https://tm-api-new.lvlp.no/api/v1',
      staticMapsKey: local.staticMapsKey,
    },
    dev: {
      apiUrl: 'https://talkmore-api.levelupify.fun/api/v1',
      newApiUrl: 'https://api-talkmore.levelupify.fun/api/v1',
      staticMapsKey: local.staticMapsKey,
    }
  }

  const isLocal = () => {
    return /^localhost/.test(window.location.hostname) || /^srv1/.test(window.location.hostname);
  }

  const getProperty = (name) => {
    var data = isLocal() ? local : remote;
    if (!isLocal()) {
      if (window.location.hostname.indexOf('.levelupify.fun') > -1) {
        return data['dev'][name]
      } else {
        return data['prod'][name]
      }
    }
    return data[name];
  };

  return {
    isLocal: isLocal(),
    isDebug: window.localStorage.getItem('is_debug'),
    apiUrl: getProperty('apiUrl'),
    newApiUrl: getProperty('newApiUrl'),
    staticMapsKey: getProperty('staticMapsKey'),
  }
})()