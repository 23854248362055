import {combineReducers} from 'redux'

import AuthenticateReducer from './authenticate' // are you signed in/out
import UserReducers from './user'; // current signed in user information
import LayoutReducers from './layout'; // current layout
import loadingReducers from './loading'; // current loading
import modalReducers from './modal'; // modal

import {reducer as toastrReducer} from 'react-redux-toastr'

export default combineReducers({
  authenticate: AuthenticateReducer,
  user: UserReducers,
  layout: LayoutReducers,
  toastr: toastrReducer,
  loading: loadingReducers,
  modal: modalReducers,
});