import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import cookies from 'js-cookie'
import moment from 'moment'
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import config from '../../config'

import Pagination from '../pagination'

const submissionReplyText = sub => {
  let text = ''
  const { reply } = sub
  if (reply && reply.errors && !reply.errors.empty) {
    text = reply.errors[0].message
  }
  return text
}

class ActivityComponent extends Component {
  constructor(props){
    super(props)

    const today = moment()
    const minus30days = moment().subtract(7, 'days')
    const start = moment(minus30days).startOf('day')
    const end = moment(today).endOf('day')

    this.state = {
      submissions: [],
      count: 0,
      page: 1,
      perPage: 50,
      subscriptions: [],
      departments: [],

      showExtendedFilters: false,

      loading: true,

      orderBy: {
        column: null,
        order: null,
      },

      selectedFilters: {
        from: start.toISOString(),
        to: end.toISOString(),
        fromToKey: 'created',
        product: [],
        campaign: [],
        childsName: false,
        searchString: '',
        onlyFailed: false,
      },

      filterValues: {},
    }
  }

  getExistingFiltersFromCookie = (cb) => {
    const selectedFilters = cookies.getJSON('TMFilters')
    if (selectedFilters) {
      this.setState({selectedFilters: selectedFilters}, () => cb())
    } else {
      cb()
    }
  };

  setFilterCookie = (cb) => {
    // console.log('this.state.selectedFilters', this.state.selectedFilters)
    cookies.set('TMFilters', this.state.selectedFilters, {expires: 0.2})
    cb()
  };

  componentDidMount() {
    this.getExistingFiltersFromCookie(() => {
      this.getResource('subscriptions')
      this.getResource('departments')
      this.getSubmissions()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state && prevState.loaded) {
      if (this.state.forceUpdate !== prevState.forceUpdate) {
        this.getSubmissions()
      }
    }
  }

  getResource = (key) => {
    axios.get(`${config.apiUrl}/${key}?perPage=100`)
      .then(r => {
        if (r.data.count) {
          console.log('getResource', key, r.data.rows)
          this.setState({[key]: r.data.rows})
        } else {
          console.log('no ', key)
        }
      })
      .catch(e => console.log('Could not fetch ', key, ' ',  e))
  };

  getSubmissions = debounce((cb) => {
    // const _this = this;
    this.setState({loading: true})
    let queryString = `?`

    // Get ordering
    if (this.state.orderBy.column && this.state.orderBy.order) {
      queryString += `&order=${this.state.orderBy.column}&direction=${this.state.orderBy.order}`
    }

    // get searchstring
    if (this.state.selectedFilters.searchString) {
      queryString += `&searchString=${this.state.selectedFilters.searchString}`
      queryString += `&searchKeys=firstName`
      queryString += `&searchKeys=lastName`
      queryString += `&searchKeys=email`
      queryString += `&searchKeys=phone`
      queryString += `&searchKeys=staticData.buyer.firstName`
      queryString += `&searchKeys=staticData.buyer.lastName`
      queryString += `&searchKeys=staticData.buyer.email`
      queryString += `&searchKeys=staticData.buyer.phone`
      queryString += `&searchKeys=staticData.sale.subscriptionName`
    }

    // only show failed
    if (this.state.selectedFilters.onlyFailed) {
      queryString += '&botSuccess=false'
    }

    // handle from/to filter
    if (this.state.selectedFilters.from && this.state.selectedFilters.to) {
      queryString += `&from=${this.state.selectedFilters.from}&to=${this.state.selectedFilters.to}&fromToKey=${this.state.selectedFilters.fromToKey}`
    }

    // handle page
    if (this.state.page) {
      queryString += `&page=${this.state.page}`
    }

    if (this.state.perPage) {
      queryString += `&page_size=${this.state.perPage}`
    }

    // Get all submissions
    axios.get(`${config.newApiUrl}/submissions${queryString}`)
      .then(r => {
        console.log(r)
        if (r.data) {
          this.setState({submissions: r.data.data, count: r.data.meta.pagination.total_entries, loading: false})
        }
      }).catch(e => {
        console.log('error fetching submissions', e)
      })
  }, 150);

  updateSelectedFilters = (filterKey, selectedFilters) => {
    const currentSelectedFilters = {...this.state.selectedFilters}
    currentSelectedFilters[filterKey] = selectedFilters
    this.setState({selectedFilters: currentSelectedFilters}, () => this.setFilterCookie(() => this.getSubmissions()))
  };

  toggleOrderBy = (key) => {
    // 1. If column is not set OR not right key, then set it to key AND 'ASC'
    if (!this.state.orderBy.column || this.state.orderBy.column !== key) {
      const orderBy = {...this.state.orderBy}
      orderBy.column = key
      orderBy.order = 'asc'
      this.setState({orderBy: orderBy}, () => this.getSubmissions())
    }
    // 2. Else if column is key but asc is set, then set desc
    else if (this.state.orderBy.column === key && this.state.orderBy.order === 'asc') {
      const orderBy = {...this.state.orderBy}
      orderBy.order = 'desc'
      this.setState({orderBy: orderBy}, () => this.getSubmissions())
    }
    // 3. REMOVE ORDER
    else {
      this.setState({orderBy: {column: null, order: null}}, () => this.getSubmissions())
    }
  };

  getColumnClass = (key) => {
    if (this.state.orderBy.column === key) {
      if (this.state.orderBy.order === 'asc') {
        return 'is-order-asc'
      } else {
        return 'is-order-desc'
      }
    }
    return ''
  };

  paginate = (e, key) => {
    e.preventDefault()
    let page = this.state.page
    if (typeof key === 'number') {
      page = key
    } else if (key === 'next') {
      page = page + 1
    } else if (key === 'previous') {
      page = page - 1
    }
    this.setState({page: page}, () => this.getSubmissions())
  };

  render () {
    const { submissions, count, subscriptions, departments } = this.state

    return (
       <main>
        <div className="main-header">
          {/*
          <div className="tm-filters">
            <div className="filter-group date-filter">
              <label className="filter-group__header">Datofilter</label>
              <DatePicker
                className="form-control"
                selected={moment(this.state.selectedFilters.from)}
                onChange={date => this.updateSelectedFilters('from', date.toISOString())}
                dateFormat="DD/MM/YYYY" />
              <div className="date-filter__separator">-</div>
              <DatePicker
                className="form-control"
                selected={moment(this.state.selectedFilters.to)}
                onChange={date => this.updateSelectedFilters('to', date.toISOString())}
                dateFormat="DD/MM/YYYY" />
            </div>

            <div className="filter-group">
              <label htmlFor="search_string">Søk (navn/tlfnr/epost/prisplan)</label>
              <input type="text" id="search_string" className="form-control" value={this.state.selectedFilters.searchString} onChange={e => this.updateSelectedFilters('searchString', e.target.value)} />
            </div>

            <div className="filter-group filter-group--vertical">
              <div>
                <input type="checkbox" id="bot_success" className="checkbox" checked={this.state.selectedFilters && this.state.selectedFilters.onlyFailed} onChange={e => this.updateSelectedFilters('onlyFailed', e.target.checked)} />
                <label htmlFor="bot_success"><span>Vis kun feilet</span></label>
              </div>
            </div>
          </div>
          */}
        </div>


          <div className="main-body --wide-layout-temp-class">
            <div className="main-body__header">
              <h1>Viser {submissions.length} av {count} salgsforsøk</h1>
              <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.submissions} />
            </div>
            <div className={`tm-table h-has-loader ${this.state.loading ? 'is-loading' : 'is-done-loading'}`}>
              <div className="lds_w"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
              <div className="tm-table__header-row">
                <div className="tm-table__cell">Tidspunkt</div>
                <div className="tm-table__cell">Vellykket?</div>
                <div className="tm-table__cell">Fornavn</div>
                <div className="tm-table__cell">Etternavn</div>
                <div className="tm-table__cell">Epost</div>
                <div className="tm-table__cell">Telefon</div>
                <div className="tm-table__cell">Salgskontor</div>
                <div className="tm-table__cell">Selger</div>
                <div className="tm-table__cell">Melding</div>
              </div>
              <div className="tm-table__body">
                {submissions.map((s) => {
                  console.log(s)
                  const departmentId = s.data.order.seller.department_id
                  return s.data.order.items.map(item => {
                    console.log('item', item)
                    return (
                      <a className={`tm-table__row plain ${s.order_id ? '' : 'xtm-table__row--emphasized'}`}
                          key={`${s.id}-${item.attributes.first_name}-${item.attributes.last_name}-${item.attributes.email}`}
                          href={`/#/activity/${s.id}`}>
                        <div className="tm-table__cell"><div className="cell-content">{s.created_at && moment(s.created_at).format('DD/MM/YYYY HH:mm')}</div></div>
                        <div className="tm-table__cell"><div className={`cell-content success-indicator ${s.order_id ? 'success' : 'failure'}`}>{s.order_id ? '' : ''}</div></div>
                        <div className="tm-table__cell"><div className="cell-content">{item.attributes.first_name}</div></div>
                        <div className="tm-table__cell"><div className="cell-content">{item.attributes.last_name}</div></div>
                        <div className="tm-table__cell"><div className="cell-content">{item.attributes.email}</div></div>
                        <div className="tm-table__cell"><div className="cell-content">{item.attributes.phone}</div></div>
                        <div className="tm-table__cell"><div className="cell-content">{departmentId && (_.find(departments, dep => dep.id === departmentId) || {}).name}</div></div>
                        <div className="tm-table__cell"><div className="cell-content">{s.user.first_name} {s.user.last_name}</div></div>
                        <div className="tm-table__cell flexible"><div className="cell-content">{submissionReplyText(s)}</div></div>
                      </a>
                    )
                  })
                })}
                {submissions.length === 0 &&
                  <div>Ingen salgsforsøk med dette søket</div>
                }
              </div>
            </div>
            <div className="main-body__footer">
              <Pagination paginate={this.paginate.bind(this)} page={this.state.page} perPage={this.state.perPage} count={this.state.count} rows={this.state.submissions} />
            </div>
          </div>


        </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

const Activity = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityComponent);

export default Activity
