import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import config from '../../config'
import _ from 'lodash'

class SubscriptionsDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      subscription: {
        name: '',
        botKey: '',
        amount: '0',
        order: '0',
        active: false,
        kidSafe: false
      }
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/subscriptions/${this.props.layout.data.subscriptionId}`)
      .then(res => {
        if (res.data) {
          this.setState({subscription: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })
  }


  deleteSubscription = (userId) => {
    axios.delete(`${config.apiUrl}/subscriptions/${this.props.layout.data.subscriptionId}`)
      .then(res =>{
        window.location = `#/subscriptions`
        toastr.success('Selger slettet fra avdeling')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke slette selger', e.response.data.message)
          console.error(e.response.data.message)
        }
      })
  };

  updateSubscription = (e) => {
    e.preventDefault()
    const {subscription} = this.state
    axios.put(`${config.apiUrl}/subscriptions/${this.props.layout.data.subscriptionId}`, {
      name: subscription.name,
      botKey: subscription.botKey,
      amount: parseInt(subscription.amount),
      order: parseInt(subscription.order),
      active: subscription.active,
      kidSafe: subscription.kidSafe
    })
      .then(res => {
        toastr.success('Abonnement oppdatert')
      })
      .catch(e => {
        console.log(e)
        if (e.response && e.response.data) {
          toastr.error('Kunne ikke oppdatere selger', e.response.data.message)
        } else {
          toastr.error('Kunne ikke oppdatere selger')
        }
      })
  };

  render () {
    const { subscription } = this.state
    console.log('subscription ', subscription)

    return !subscription ? <div>Loading...</div> : (
      <main>
        <div className="main-body --wide-layout-temp-class">
          <div className="main-body__header">
            <h1>Viser {subscription.name}</h1>
            <a href="#/subscriptions">Tilbake</a>
          </div>

          <div className="tm-section-card">
            <h1 className="tm-section__header">Endre prisplanen</h1>
            <div className="tm-section-card__data add-user">
              <form onSubmit={e => this.updateSubscription(e)} id="update_subscription" className="add-user__form">

                <div className="input_wrapper">
                  <label htmlFor="name">Navn:</label>
                  <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Navn" value={subscription.name} onChange={e => {
                    const copy = Object.assign({}, subscription)
                    copy.name = e.target.value
                    this.setState({subscription: copy})
                  }} />
                  <small id="emailHelp" className="form-text text-muted">Navnet til prisplanen. Dette vil være synlig i salgskanaler.</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="botKey">Bot-nøkkel:</label>
                  <input type="text" className="form-control" id="botKey" aria-describedby="botKeyHelp" placeholder="Bot-nøkkel" value={subscription.botKey} onChange={e => {
                    const copy = Object.assign({}, subscription)
                    copy.botKey = e.target.value
                    this.setState({subscription: copy})
                  }} />
                  <small id="botKeyHelp" className="form-text text-muted">Nøkkelen som brukes når salg mates inn via BOT</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="amount">Kroner/mnd:</label>
                  <input type="text" min={0} className="form-control" id="amount" aria-describedby="amountHelp" placeholder="0" value={subscription.amount || ''} onChange={e => {
                    const copy = Object.assign({}, subscription)
                    copy.amount = e.target.value
                    this.setState({subscription: copy})
                  }} />
                </div>

                <div className="input_wrapper">
                  <label htmlFor="order">Rekkefølge:</label>
                  <input type="text" min={0} className="form-control" id="order" aria-describedby="orderHelp" placeholder="0" value={subscription.order || ''} onChange={e => {
                    const copy = Object.assign({}, subscription)
                    copy.order = e.target.value
                    this.setState({subscription: copy})
                  }} />
                  <small id="orderHelp" className="form-text text-muted">Sorteringsnøkkel for visning i salgskanaler</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="active">Aktiv:</label>
                  <div class="select_wrapper">
                    <select className="form-control" value={subscription.active} onChange={e => {
                      const copy = Object.assign({}, subscription)
                      copy.active = e.target.value
                      this.setState({subscription: copy})
                    }}>
                      <option value="true">Aktiv</option>
                      <option value="false">Ikke aktiv</option>
                    </select>
                  </div>
                  <small id="active" className="form-text text-muted">Om prisplanen er <strong>aktiv</strong> vil det vises i salgskanaler.</small>
                </div>

                <div className="input_wrapper">
                  <label htmlFor="kidSafe">Trygg:</label>
                  <div class="select_wrapper">
                    <select className="form-control" value={subscription.kidSafe} onChange={e => {
                      const copy = Object.assign({}, subscription)
                      copy.kidSafe = e.target.value
                      this.setState({subscription: copy})
                    }}>
                      <option value="true">Trygg</option>
                      <option value="false">Ikke trygg</option>
                    </select>
                  </div>
                </div>

                <button type="submit">Oppdater prisplanen</button>
              </form>
              { /* <button class="btn btn-danger small-button" onClick={e => this.deleteSubscription(e)}>Slett</button> */ }
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsDetails)
