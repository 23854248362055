import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'

class RenderRoleListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      permissions: null,
    }
  }

  componentDidMount() {
    axios.get(`${config.apiUrl}/roles/${this.props.role.id}/permissions`)
      .then(res => {
        if (res.data) {
          this.setState({permissions: res.data})
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  render() {
    const { role } = this.props
    const { permissions } = this.state

    return (
      <div key={`role-top-${role.id}`}>
        <a href={`/#/roles/${role.id}`} className="tm-table__row">
          <div className="tm-table__cell">{role.name ? role.name : 'Mangler navn'}</div>
          <div className="tm-table__cell">{permissions ? permissions.length : 'Laster inn'}</div>
        </a>
      </div>
    )
  }
}

class RolesListComponent extends Component {
  constructor(props){
    super(props)
    this.state = {
      count: null,
      roles: [],
      loading: true
    }
  }

  componentDidMount() {
    // get top level departments
    axios.get(`${config.apiUrl}/roles?order=name`)
      .then(res => {
        if (res.data.rows) {
          this.setState({roles: res.data.rows, loading: false})
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  render () {
    const { roles } = this.state

    return (
      <main>
        <div className="main-body">
          <div className="tm-section-card">
            <h1 className="tm-section-card__header">Roles</h1>
            <div className="tm-table tm-table--narrow tm-section-card__data">
              <div className="tm-table__header-row">
                <div className="tm-table__cell">Rolle</div>
                <div className="tm-table__cell">Antall brukere</div>
              </div>
              <div className="tm-table__body">
                {roles.map((role) => {
                  return <RenderRoleListRow key={`roleTop${role.name}`} role={role} roles={roles} />
                })}
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => { 
  return {
    dispatch: dispatch,
  }
}

const RolesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesListComponent);

export default RolesList